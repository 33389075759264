import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';

import { AuthContext } from '../auth';
import { useLazyQuery } from '@apollo/client';
import {
  FETCH_ALL_NEW_ITEMS_COUNT,
  FETCH_NEW_INVITE_COUNT,
  FETCH_NEW_ALERT_COUNT,
  FETCH_NEW_CALL_COUNT,
  FETCH_NEW_NOTE_COUNT,
  FETCH_NEW_CAREPLAN_COUNT,
  FETCH_NEW_COORDINATE_COUNT,
  FETCH_NEW_REPORT_COUNT,
  FETCH_NEW_PRESCRIBE_COUNT,
} from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';

export const ProviderContext = createContext();

export const ProviderContextProvider = ({ children }) => {
  const { me } = useContext(AuthContext);

  const [schedulerSet, updateSchedulerSet] = useState(false);
  const [callMasking, updateCallMasking] = useState(null);

  const [inviteCount, setInviteCount] = useState();
  const [reportCount, setReportCount] = useState();
  const [noteCount, setNoteCount] = useState();
  const [careplanCount, setCareplanCount] = useState();
  const [coordinateCount, setCoordinateCount] = useState();
  const [prescribeCount, setPrescribeCount] = useState();
  const [callCount, setCallCount] = useState();
  const [alertCount, setAlertCount] = useState();

  const [fetchAllNewItemsCount] = useLazyQuery(FETCH_ALL_NEW_ITEMS_COUNT);
  const [fetchInviteCount] = useLazyQuery(FETCH_NEW_INVITE_COUNT);
  const [fetchReportCount] = useLazyQuery(FETCH_NEW_REPORT_COUNT);
  const [fetchNoteCount] = useLazyQuery(FETCH_NEW_NOTE_COUNT);
  const [fetchCareplanCount] = useLazyQuery(FETCH_NEW_CAREPLAN_COUNT);
  const [fetchCoordinateCount] = useLazyQuery(FETCH_NEW_COORDINATE_COUNT);
  const [fetchCallCount] = useLazyQuery(FETCH_NEW_CALL_COUNT);
  const [fetchAlertCount] = useLazyQuery(FETCH_NEW_ALERT_COUNT);
  const [fetchPrescribeCount] = useLazyQuery(FETCH_NEW_PRESCRIBE_COUNT);

  const { subscribe } = useContext(SocketContext);
  const inviteUpdateSubscription = useRef();
  const reportUpdateSubscription = useRef();
  const noteUpdateSubscription = useRef();
  const careplanUpdateSubscription = useRef();
  const callUpdateSubscription = useRef();
  const alertUpdateSubscription = useRef();
  const prescribeUpdateSubscription = useRef();

  useEffect(() => {
    if (!!me?._id) {
      updateSchedulerSet(
        me.activeProviderPractice?.operationSchedule?.length > 0
      );
      updateCallMasking(me.phones?.masking);

      fetchAllNewItemsCount({
        onCompleted: (data) => {
          const allNewItemsCount = data?.allNewItemsCount || {};
          setInviteCount(allNewItemsCount.invite || 0);
          setReportCount(allNewItemsCount.report || 0);
          setNoteCount(allNewItemsCount.note || 0);
          setCareplanCount(allNewItemsCount.careplan || 0);
          setCoordinateCount(allNewItemsCount.coordinateCount || 0);
          setAlertCount(allNewItemsCount.alert || 0);
          setCallCount(allNewItemsCount.call || 0);
          setPrescribeCount(allNewItemsCount.prescribe || 0);
        },
      });

      inviteUpdateSubscription.current?.unsubscribe();
      inviteUpdateSubscription.current = subscribe(EVENTS.INVITE_UPDATE, () => {
        fetchInviteCount({
          onCompleted: (data) => setInviteCount(data.inviteCount),
        });
      });

      // reportUpdateSubscription.current?.unsubscribe();
      // reportUpdateSubscription.current = subscribe(EVENTS.REPORT_UPDATE, () => {
      //   fetchReportCount({
      //     onCompleted: (data) => setReportCount(data.reportCount),
      //   });
      // });

      noteUpdateSubscription.current?.unsubscribe();
      noteUpdateSubscription.current = subscribe(EVENTS.PATIENT_NOTES, () => {
        fetchNoteCount({
          onCompleted: (data) => setNoteCount(data.noteCount),
        });
        fetchCoordinateCount({
          onCompleted: (data) => setCoordinateCount(data.coordinateCount),
        });
      });

      careplanUpdateSubscription.current?.unsubscribe();
      careplanUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_CAREPLANS,
        () => {
          fetchCareplanCount({
            onCompleted: (data) => setCareplanCount(data.careplanCount),
          });
          fetchCoordinateCount({
            onCompleted: (data) => setCoordinateCount(data.coordinateCount),
          });
        }
      );

      // callUpdateSubscription.current?.unsubscribe();
      // callUpdateSubscription.current = subscribe(EVENTS.CALL_UPDATE, () => {
      //   fetchCallCount({
      //     onCompleted: (data) => setCallCount(data.callCount),
      //   });
      // });

      alertUpdateSubscription.current?.unsubscribe();
      alertUpdateSubscription.current = subscribe(EVENTS.ALERTS_UPDATE, () => {
        fetchAlertCount({
          onCompleted: (data) => setAlertCount(data.alertCount),
        });
      });

      // prescribeUpdateSubscription.current?.unsubscribe();
      // prescribeUpdateSubscription.current = subscribe(
      //   EVENTS.PRESCRIBE_UPDATE,
      //   () => {
      //     fetchPrescribeCount({
      //       onCompleted: (data) => setPrescribeCount(data.prescribeCount),
      //     });
      //   }
      // );
    } else {
      inviteUpdateSubscription.current?.unsubscribe();
      reportUpdateSubscription.current?.unsubscribe();
      noteUpdateSubscription.current?.unsubscribe();
      careplanUpdateSubscription.current?.unsubscribe();
      callUpdateSubscription.current?.unsubscribe();
      alertUpdateSubscription.current?.unsubscribe();
      prescribeUpdateSubscription.current?.unsubscribe();
      setInviteCount(null);
    }
  }, [me?._id]);

  return (
    <ProviderContext.Provider
      value={{
        Provider: me,
        inviteCount,
        reportCount,
        noteCount,
        careplanCount,
        coordinateCount,
        alertCount,
        callCount,
        prescribeCount,
        schedulerSet,
        updateSchedulerSet,
        callMasking,
        updateCallMasking,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export default ProviderContextProvider;
