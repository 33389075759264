import { useState, useCallback } from 'react';
import {
  Row,
  Column,
  ScrollView,
  Text,
  Button,
} from '@gaz/gaz-components.public';

import { Container, Tabs, Popover } from 'common';
import MedicationItem from './Item';
import Instruction from './Instruction';
import assets from 'assets';

const Main = ({
  medications,
  updateMedications,
  handleAdd,
  handleAddAlerts,
  handleShowPrevious,
  handleSave,
  handleClose,
  forOrder,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [deletingMedication, setDeletingMedication] = useState();
  const [editingMedication, setEditingMedication] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let hasIncompleteMedication = false;
  Object.values(medications).forEach((medication) => {
    if (!medication.frequency) {
      hasIncompleteMedication = true;
    }
  });

  const onRemove = useCallback((medication) => {
    setShowDeleteModal(true);
    setDeletingMedication(medication);
  }, []);

  const onConfirmDelete = () => {
    delete medications[deletingMedication.ndc];
    setShowDeleteModal(false);
    updateMedications({ ...medications });
  };

  const onEditMedication = useCallback((medication) => {
    setEditingMedication(medication);
  }, []);

  const handleSaveInstruction = (medication, frequency, alerts) => {
    medication.frequency = frequency;
    // medication.alerts = alerts;
    updateMedications({ ...medications });
  };

  const handleGroupFrequency = (
    medication,
    groupedMedications,
    value,
    type
  ) => {
    if (type === 'frequency') {
      medication.frequency = value;
      Object.keys(groupedMedications).forEach(
        (ndc) => (medications[ndc] = { ...medications[ndc], frequency: value })
      );
    } else if (type === 'alerts') {
      medication.alerts = value;
      Object.keys(groupedMedications).forEach(
        (ndc) => (medications[ndc] = { ...medications[ndc], alerts: value })
      );
    } else {
      medication.mods = value;
      Object.keys(groupedMedications).forEach(
        (ndc) => (medications[ndc] = { ...medications[ndc], mods: value })
      );
    }
    updateMedications({ ...medications });
  };

  const selectTab = (tab) => {
    if (tab === 1) {
      handleShowPrevious();
    }
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Column modifiers={['col']}>
            <Text modifiers={['bold', 'block']}>Medications</Text>
            <Text modifiers={['muted', 'block', 'medium']}>
              {forOrder
                ? 'Search and Add meds then send to pharmacy'
                : 'Create medication care plan (CP)'}
            </Text>
          </Column>
          <Button
            modifiers={['success', 'roundCorner', 'widthXSmall', 'noShadow']}
            onClick={handleAdd}
          >
            + ADD
          </Button>
        </Row>
      </Container>
      <Container
        modifiers={[
          'flexBox',
          'flexOne',
          'fullHeight',
          'backgroundWhite',
          'fluid',
        ]}
      >
        <Container modifiers={['flexFixed', 'topGutters_1']}>
          <Tabs current={activeTab} onChange={selectTab} noSeperator>
            <Tabs.Tab
              title="Recently added"
              modifiers={['xSmallPadding', 'noTab']}
            />
            <Tabs.Tab
              title="Previous medication"
              modifiers={['xSmallPadding']}
            />
          </Tabs>
        </Container>
        <ScrollView>
          {Object.keys(medications).length > 0 ? (
            Object.entries(medications).map(([ndc, medication]) => (
              <MedicationItem
                medication={medication}
                key={ndc}
                noAlerts={forOrder}
                onRemove={() => {
                  onRemove(medication);
                }}
                onEdit={() => {
                  onEditMedication(medication);
                }}
              />
            ))
          ) : (
            <Row modifiers={['fullHeight', 'middle', 'center']}>
              <Container>
                <img src={assets.icons.icNoMedications} />
                <Row modifiers={['withGutters']}>
                  <Text>
                    {forOrder
                      ? 'Search and Add meds then send to pharmacy'
                      : 'There are no care plan medication tasks. Please select from previous medication plans or press add to create a new medication plan.'}
                  </Text>
                </Row>
              </Container>
            </Row>
          )}
        </ScrollView>
      </Container>
      <Container modifiers={['flexFixed', 'footer', 'backgroundWhite']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Button
            image={assets.icons.icCloseGray}
            modifiers={['icon', 'transparent']}
            onClick={handleClose}
          />
          {!forOrder && (
            <Button
              modifiers={[
                'widthSmall',
                'roundCorner',
                Object.keys(medications).length === 0 && 'disabled',
              ]}
              onClick={handleAddAlerts}
            >
              Alerts
            </Button>
          )}
          <Button
            modifiers={[
              (Object.keys(medications).length === 0 ||
                hasIncompleteMedication) &&
                'disabled',
              'widthSmall',
              'roundCorner',
            ]}
            disabled={
              hasIncompleteMedication || Object.keys(medications).length === 0
            }
            onClick={handleSave}
          >
            {forOrder
              ? `View Cart(${Object.keys(medications).length})`
              : 'Save'}
          </Button>
        </Row>
      </Container>
      <Popover
        open={showDeleteModal}
        onConfirm={onConfirmDelete}
        onClose={() => setShowDeleteModal(false)}
        footer
      >
        <Text modifiers={['block', 'center']}>
          Are you sure want to delete this medication?
        </Text>
      </Popover>
      {editingMedication && (
        <Instruction
          medication={editingMedication}
          medications={medications}
          handleClose={() => setEditingMedication(null)}
          handleDone={handleSaveInstruction}
          handleGrouping={handleGroupFrequency}
        />
      )}
    </Container>
  );
};

export default Main;
