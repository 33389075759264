import {
  useEffect,
  useState,
  useMemo,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';

import { MainLayout } from 'layouts';
import { Container, SearchBar, Text } from '@gaz/gaz-components.public';
import { BackButton, PeriodPicker } from 'common';
import { FETCH_PROVIDER_ORDERS } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import { getFullName } from 'utils/string';
import assets from 'assets';
import { EVENTS, SocketContext } from 'contexts/socket';

import OrderCard from './OrderCard';
import OrderDetail from '../Pharmacy/Detail';

const { Header, Content } = MainLayout;

export default () => {
  const history = useHistory();
  const [selectedPeriod, updateSelectedPeriod] = useState('3m');
  const [searchQuery, updateSearchQuery] = useState('');
  const [
    fetchProviderOrders,
    { data: { providerMedicationOrders: orders } = {}, loading },
  ] = useLazyQuery(FETCH_PROVIDER_ORDERS, {
    fetchPolicy: 'no-cache',
  });
  const [selectedOrder, setSelectedOrder] = useState();

  const { subscribe } = useContext(SocketContext);
  const orderSubscription = useRef();

  useEffect(() => {
    fetchProviderOrders({ variables: { period: selectedPeriod } });
  }, [selectedPeriod]);

  const filteredOrders = useMemo(() => {
    if (!orders) {
      return [];
    }
    const query = (searchQuery?.trim() || '').toLowerCase();
    if (query === '') {
      return orders;
    }
    return orders.filter((order) =>
      getFullName(order.patient).toLocaleLowerCase().includes(query)
    );
  }, [orders, searchQuery]);

  useEffect(() => {
    orderSubscription.current?.unsubscribe();
    orderSubscription.current = subscribe(EVENTS.MEDICATION_ORDERS, () => {
      fetchProviderOrders({
        variables: { period: selectedPeriod },
      });
    });
    return () => {
      orderSubscription.current?.unsubscribe();
    };
  }, [selectedPeriod, fetchProviderOrders]);

  const handleClickOrder = useCallback((order) => {
    setSelectedOrder(order);
  }, []);

  const goBack = () => {
    if (selectedOrder) {
      setSelectedOrder(null);
    } else {
      history.replace('');
    }
  };

  loadingVar(loading);

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Text modifiers={['bold']}>
            {selectedOrder ? (
              <Text modifiers={['bold']}>
                Order #
                <Text modifiers={['primary']}>
                  {selectedOrder.orderNumber.toUpperCase()}
                </Text>
              </Text>
            ) : (
              <Text modifiers={['bold']}>e-Prescribe</Text>
            )}
          </Text>
        </Header.Center>
        <Header.Right>
          {!selectedOrder && (
            <PeriodPicker
              value={selectedPeriod}
              icon={assets.icons.iconFilter}
              actionTitle="Filter by time period"
              handleSelect={updateSelectedPeriod}
            />
          )}
        </Header.Right>
      </Header>
      {!selectedOrder && (
        <Container>
          <SearchBar onChange={updateSearchQuery} placeholder="Search" />
        </Container>
      )}
      <Content>
        {selectedOrder ? (
          <OrderDetail order={selectedOrder} viewOnly />
        ) : (
          <Container>
            {filteredOrders.map((order) => (
              <OrderCard
                key={order._id}
                order={order}
                onClick={handleClickOrder}
              />
            ))}
          </Container>
        )}
      </Content>
    </MainLayout>
  );
};
