import React, { useState } from 'react';
import styled from 'styled-components';

import { Formik, Field, Form } from 'formik';
import {
  Row,
  Column,
  VR,
  Text,
  TextArea,
  Button,
} from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';
import { Container, FormPicker, FormTimePicker, FormDatePicker } from 'common';

const DurationMins = [
  { id: '5', name: '5 mins' },
  { id: '10', name: '10 mins' },
  { id: '15', name: '15 mins' },
  { id: '30', name: '30 mins' },
  { id: '45', name: '45 mins' },
  { id: '60', name: '60 mins' },
];

const initialValues = {
  date: Date(),
  time: '12:00 am',
  duration: '5',
};

const Component = ({}) => {
  const [showProviderSelection, setShowProviderSelection] = useState(false);

  const handleSubmit = (data) => {
    console.log(data);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <Container modifiers={['fullHeight', 'flexBox', 'fluid']}>
          <Container modifiers={['flexOne']}>
            <Container modifiers={['flexFixed']}>
              <Row>
                <Column modifiers={['col_4']}>
                  <Field
                    hasLabel
                    labelLightWeight
                    asInfo
                    noBorder
                    name="selectedDate"
                    title="Select date"
                    placeholder="Date"
                    maxDate={Date()}
                    dateFormat="MM/DD/YY"
                    component={FormDatePicker}
                  />
                  <VR modifiers={['padding_2']} />
                </Column>
                <Column modifiers={['col_4']}>
                  <Field
                    hasLabel
                    labelLightWeight
                    asInfo
                    noBorder
                    name="time"
                    placeholder="Time"
                    component={FormTimePicker}
                  />
                  <VR modifiers={['padding_2']} />
                </Column>
                <Column modifiers={['col_4']}>
                  <Field
                    hasLabel
                    labelLightWeight
                    asInfo
                    noBorder
                    name="duration"
                    placeholder="Duration"
                    options={DurationMins}
                    component={FormPicker}
                  />
                </Column>
              </Row>
            </Container>
            <Container>
              <Row>
                <Text
                  onClick={() =>
                    setShowProviderSelection(!showProviderSelection)
                  }
                >
                  Select provider(s) <Text modifiers={['info']}>V</Text>
                </Text>
              </Row>
              {showProviderSelection && <Container>Hey</Container>}
            </Container>
            <Container>
              <Row>
                <Text>Task description</Text>
              </Row>
              <Row>
                <Field name="description" component={TextArea} />
              </Row>
            </Container>
          </Container>
          <Container modifiers={['flexFixed', 'footer']}>
            <Row modifiers={['spaceAround']}>
              <Button modifiers={['widthSmall', 'roundCorner']} type="submit">
                Save
              </Button>
              <Button
                modifiers={['widthSmall', 'outlinePrimary', 'roundCorner']}
              >
                Cancel
              </Button>
            </Row>
          </Container>
        </Container>
      </Form>
    </Formik>
  );
};

const ActiveCareplanAddTimeSection = buildStyledComponent(
  'ActiveCareplanAddTimeSection',
  styled(Component),
  ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeMedium};
    text-align: center;
  `
);

export default ActiveCareplanAddTimeSection;
