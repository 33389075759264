import React, { forwardRef, useRef } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import {
  FormInput,
  FormSsnInput,
  Container,
  Row,
  Column,
  Button,
  Avatar,
} from '@gaz/gaz-components.public';
import { FormFileInput, FormDatePicker, FormPicker } from 'common';
import schema from 'utils/schema';
import assets from 'assets';
import { GENDERS, STATUSES } from 'utils/constants';

const profileSchema = Yup.object().shape({
  firstName: schema.firstName,
  lastName: schema.lastName,
  middleName: schema.middleName,
  ssn: schema.ssn,
  dob: schema.dob,
  gender: schema.gender,
  maritalStatus: schema.status,
  photo: schema.file,
  insurances: schema.insurances,
});

const initialValues = {
  firstName: '',
  lastName: '',
  middleName: '',
  ssn: '',
  dob: '',
  gender: '',
  maritalStatus: '',
  photo: null,
  // insurance
  insurances: [
    {
      company: '',
      id: '',
      groupNumber: '',
      card: null,
    },
  ],
};

const H3 = styled.h3(
  ({ theme }) => `
    font-size: ${theme.dimensions.fontSize};
    color: ${theme.colors.text};
    margin: 0;
    padding: 0;
  `
);

const Card = styled.div(
  ({ theme }) => `
    box-shadow: ${theme.dimensions.boxShadow};
    padding: ${theme.dimensions.padding_2};
    border-radius: ${theme.dimensions.borderRadius_1};
  `
);

const Profile = forwardRef((props, ref) => {
  const initialized = useRef(false);
  if (props.show) initialized.current = true;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={profileSchema}
      innerRef={ref}
      onSubmit={() => {}}
    >
      {({ values }) =>
        initialized.current && (
          <Form style={{ display: props.show ? null : 'none' }}>
            <Row modifiers={['middle']}>
              <Column modifiers={['col_4']}>
                <Row modifiers={['center']}>
                  <Field
                    // hasLabel
                    required
                    name="photo"
                    placeholder="Photo"
                    component={FormFileInput}
                    accept="image/*"
                    width={90}
                    height={90}
                    addText="Add Photo"
                    editText="Edit"
                    modifiers={['round', 'flexColumn']}
                    s3={{ folder: 'patients' }}
                  >
                    <Avatar
                      width={90}
                      height={90}
                      image={assets.icons.photoCamera}
                    />
                  </Field>
                </Row>
              </Column>
              <Column modifiers={['col_8']}>
                <Row>
                  <Column modifiers={['col_12']}>
                    <Field
                      required
                      hasLabel
                      name="firstName"
                      placeholder="First Name"
                      component={FormInput}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column modifiers={['col_6']}>
                    <Field
                      hasLabel
                      name="middleName"
                      placeholder="Middle Name"
                      component={FormInput}
                    />
                  </Column>
                  <Column modifiers={['col_6']}>
                    <Field
                      required
                      hasLabel
                      name="lastName"
                      placeholder="Last Name"
                      component={FormInput}
                    />
                  </Column>
                </Row>
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col_6']}>
                <Field
                  required
                  hasLabel
                  name="ssn"
                  placeholder="SSN"
                  component={FormSsnInput}
                />
              </Column>
              <Column modifiers={['col_6']}>
                <Field
                  required
                  hasLabel
                  name="dob"
                  placeholder="Date of birth"
                  maxDate={new Date()}
                  component={FormDatePicker}
                />
              </Column>
            </Row>

            <FieldArray
              name="insurances"
              render={(arrayHelpers) => (
                <div>
                  {values.insurances.map((insurance, index) => (
                    <Container key={index}>
                      <Card>
                        <Row modifiers={['middle']}>
                          <Column modifiers={['col']}>
                            <H3>Insurance #{index + 1}</H3>
                          </Column>
                          {index !== 0 && (
                            <Column modifiers={['noPadding']}>
                              <Button
                                modifiers={['transparent', 'icon']}
                                image={assets.icons.close}
                                imageWidth={30}
                                imageHeight={30}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </Column>
                          )}
                        </Row>
                        <Row>
                          <Column modifiers={['col_12']}>
                            <Field
                              required
                              hasLabel
                              name={`insurances.${index}.company`}
                              placeholder="Company"
                              component={FormInput}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column modifiers={['col_12']}>
                            <Field
                              hasLabel
                              name={`insurances.${index}.id`}
                              placeholder="Insurance ID"
                              component={FormInput}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column modifiers={['col_12']}>
                            <Field
                              hasLabel
                              name={`insurances.${index}.groupNumber`}
                              placeholder="Group #"
                              component={FormInput}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column modifiers={['col_12']}>
                            <Field
                              hasLabel
                              name={`insurances.${index}.card`}
                              placeholder="Card"
                              component={FormFileInput}
                              accept="image/*"
                              height={150}
                              previewHeight={150}
                              s3={{ folder: 'insurances' }}
                              isCard
                            >
                              {/* <Button modifiers={['secondary']}>
                                Scan Card
                              </Button> */}
                              <Button
                                modifiers={['transparent', 'icon']}
                                image={assets.icons.photoCamera}
                                width={90}
                                height={90}
                                imageWidth={40}
                                imageHeight={40}
                              >
                                Add Card
                              </Button>
                            </Field>
                          </Column>
                        </Row>
                        {index === values.insurances.length - 1 && (
                          <Row>
                            <Column modifiers={['col_12']}>
                              <Button
                                modifiers={['outlinePrimary', 'block']}
                                onClick={() =>
                                  arrayHelpers.push({
                                    company: '',
                                    id: '',
                                    groupNumber: '',
                                    card: '',
                                  })
                                }
                              >
                                + Add Insurance
                              </Button>
                            </Column>
                          </Row>
                        )}
                      </Card>
                    </Container>
                  ))}
                </div>
              )}
            />
            <Row>
              <Column modifiers={['col_6']}>
                <Field
                  hasLabel
                  name="gender"
                  placeholder="Gender"
                  options={GENDERS}
                  component={FormPicker}
                  title="Sex assigned at birth"
                />
              </Column>
              <Column modifiers={['col_6']}>
                <Field
                  hasLabel
                  name="maritalStatus"
                  placeholder="Status"
                  options={STATUSES}
                  component={FormPicker}
                  title="Status assigned at birth"
                />
              </Column>
            </Row>
          </Form>
        )
      }
    </Formik>
  );
});

export default Profile;
