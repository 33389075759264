import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  Row,
  Column,
  FormInput,
  Button,
  A,
} from '@gaz/gaz-components.public';

import { FormCheckbox } from 'common';

const userSchema = Yup.object().shape({
  npi: Yup.string().length(10, 'NPI number should be 10 digits.'),
  termsAgreed: Yup.bool().isTrue('Please accept the Terms and Conditions.'),
});

export default function ProfessionalInfo({ initialValues, onSubmit }) {
  const handleSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={userSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row>
              <Column modifiers={['col_12']}>
                <Field
                  name="npi"
                  type="number"
                  hasLabel
                  label="Individual NPI"
                  largeLabel
                  maxLength={10}
                  placeholder="Enter individual NPI (10 digits)"
                  modifiers={['squared']}
                  component={FormInput}
                />
              </Column>
              <Column modifiers={['col_12']}>
                <Field
                  name="termsAgreed"
                  label={
                    <>
                      Agree to our <A>Terms & Conditions</A>
                    </>
                  }
                  type="checkbox"
                  modifiers={['squared']}
                  largeLabel
                  hasCheckMark
                  component={FormCheckbox}
                />
              </Column>
            </Row>
            <Row modifiers={['withGutters', 'center']}>
              <Button
                type="submit"
                modifiers={['primary', 'widthMedium', 'roundCorner']}
              >
                Register
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
