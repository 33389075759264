import { useState } from 'react';
import {
  Button,
  Column,
  Text,
  ScrollView,
  Checkbox,
} from '@gaz/gaz-components.public';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { Modal, SlidingPane } from 'common';
import ShipmentConfirm from './ShipmentConfirm';
import { MedicationItem } from './Item';
import { Row, Container } from 'common';
import { displayTime } from 'utils/time';
import { formatTitleAndName, maskString, MASK_TYPE } from 'utils/string';
import assets from 'assets';

import { loadingVar } from 'graphql/cache';
import { PROCESS_MED_ORDER } from 'graphql/mutations';
import { buildStyledComponent, px2rem } from 'style';

const InfoRow = ({ title, value, large }) => (
  <Row>
    <Column modifiers={[large ? 'col_6' : 'col_3', 'noPadding']}>
      <Text modifiers={['medium']}>{title}</Text>
    </Column>
    <Column modifiers={['col', 'noPadding']}>
      <Text modifiers={['medium', 'primary']}>{value}</Text>
    </Column>
  </Row>
);

const StatusButton = buildStyledComponent(
  'OrderStatusSelectButton',
  styled(({ status, handleShowModal, ...props }) => (
    <Container {...props} onClick={handleShowModal}>
      <Button modifiers={['roundCorner']}>{status}</Button>
      <img src={assets.icons.icChevronDown} />
    </Container>
  )),
  ({ theme, status }) => `
    width: fit-content;
    position: relative;
    padding: 0;

    > :first-child {
      width: ${px2rem(150)};
      text-transform: capitalize;
      background: ${theme.colors.orange};
      color: ${theme.colors.text};
    }

    > :last-child {
      position: absolute;
      right: ${px2rem(8)};
      top: ${px2rem(8)};
    }
  `
);

const OrderDetail = ({ order, handleBack, handleAfterUpdate, viewOnly }) => {
  const practice = order.provider.activeProviderPractice.practice;
  const provider = order.provider;
  const patient = order.patient;
  const [showShipmentModal, setShowShipmentModal] = useState(false);
  const [showStatusPane, setShowStatusPane] = useState(false);
  const [selectedStatus, updateSelectedStatus] = useState(
    order.status === 'shipped' || order.status === 'received'
      ? order.status
      : null
  );

  const [updateOrder] = useMutation(PROCESS_MED_ORDER, {
    onCompleted: (data) => {
      handleAfterUpdate();
      handleBack();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSendPMS = async () => {
    loadingVar(true);
    await updateOrder({
      variables: {
        order: order._id,
        status: 'sent',
      },
    });
    loadingVar(false);
  };

  const handleShip = () => {
    setShowShipmentModal(true);
  };
  const handleConfirmShipment = async (tracking) => {
    setShowShipmentModal(false);
    loadingVar(true);
    await updateOrder({
      variables: {
        order: order._id,
        status: 'shipped',
        tracking,
      },
    });
    loadingVar(false);
  };
  const handleStatusUpdate = async () => {
    setShowStatusPane(false);
    if (order.status === selectedStatus) {
      return;
    }
    loadingVar(true);
    await updateOrder({
      variables: {
        order: order._id,
        status: selectedStatus,
      },
    });
    loadingVar(false);
  };
  const handleClosePane = () => {
    setShowStatusPane(false);
    updateSelectedStatus(order.status);
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <ScrollView modifiers={['noPadding']}>
        {order.status !== 'placed' && (
          <Container modifiers={['backgroundDarkGray']}>
            <Text modifiers={['white', 'bold']}>
              {order.status === 'sent'
                ? `Sent to PMS: ${displayTime(
                    order.updatedAt,
                    'MM/DD/YY @ hh:mm a'
                  )}`
                : 'Status: shipped'}
            </Text>
          </Container>
        )}
        <Container modifiers={['padding_2']}>
          <Container modifiers={['section']}>
            <Text>Ordering Provider</Text>
            <Row modifiers={['topGutters_2']}>
              <Text modifiers={['block', 'medium']}>
                {formatTitleAndName(provider)}
              </Text>
            </Row>
            <Row>
              <Text modifiers={['medium']}>{practice.name}</Text>
            </Row>
            <Row>
              <Text
                modifiers={['primary', 'block', 'medium', 'preserveLineBreak']}
              >
                {`${practice.address.addressLine1}\n${practice.address.city}, ${practice.address.state} ${practice.address.zipcode}`}
              </Text>
            </Row>
            <InfoRow title="NPI:" value={provider.npi} />
            <InfoRow
              title="Phone:"
              value={provider.phones.mobile || provider.phones.work}
            />
            <InfoRow title="Fax:" value={provider.fax} />
            <InfoRow title="Email:" value={provider.email} />
          </Container>
          <Container modifiers={['section']}>
            <Text>Patient</Text>
            <Row modifiers={['topGutters_2']}>
              <Text modifiers={['block', 'medium']}>
                {formatTitleAndName(patient)}
              </Text>
            </Row>
            <Row>
              <Text
                modifiers={['primary', 'block', 'medium', 'preserveLineBreak']}
              >
                {patient.address &&
                  `${patient.address.addressLine1}\n${patient.address.city}, ${patient.address.state} ${patient.address.zipcode}`}
              </Text>
            </Row>
            <InfoRow
              title="DoB:"
              value={displayTime(patient.dob, 'MM/DD/YYYY')}
            />
            <InfoRow
              title="SS#:"
              value={maskString(patient.ssn, MASK_TYPE.SSN)}
            />
            <InfoRow title="C:" value={patient.phones.mobile} />
            <InfoRow title="H:" value={patient.phones.home} />
            <InfoRow title="Email:" value={patient.email} />
          </Container>
        </Container>
        <Container modifiers={['backgroundDarkGray']}>
          <Text modifiers={['white', 'bold']}>Order details</Text>
        </Container>
        <Container modifiers={['padding_2']}>
          <Container modifiers={['section']}>
            <InfoRow
              large
              title="Order date / time:"
              value={displayTime(order.createdAt, 'MM/DD/YY hh:mm a')}
            />
            <InfoRow
              large
              title="Order quantity:"
              value={Object.values(order.medications).length}
            />
            <InfoRow
              large
              title="Order#"
              value={order.orderNumber.toUpperCase()}
            />
            <Row modifiers={['withGutters']}>
              <Text modifiers={['block', 'medium']}>Order List</Text>
            </Row>
            {Object.values(order.medications).map((medication, index) => (
              <MedicationItem
                medication={medication}
                index={index + 1}
                key={index}
              />
            ))}
          </Container>
        </Container>
      </ScrollView>
      {!viewOnly && (
        <>
          <Container modifiers={['flexFixed', 'backgroundWhite', 'footer']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                modifiers={['icon', 'transparent']}
                image={assets.icons.icCloseGray}
                onClick={handleBack}
              />
              {order.status === 'placed' && (
                <Button
                  modifiers={['primary', 'widthSmall', 'roundCorner']}
                  onClick={handleSendPMS}
                >
                  Send to PMS
                </Button>
              )}
              {order.status === 'sent' && (
                <Button
                  modifiers={['primary', 'widthSmall', 'roundCorner']}
                  onClick={handleShip}
                >
                  Ship
                </Button>
              )}
              {(order.status === 'shipped' || order.status === 'received') && (
                <StatusButton
                  handleShowModal={() => setShowStatusPane(true)}
                  status={order.status}
                />
              )}
            </Row>
          </Container>
          <SlidingPane
            isOpen={showStatusPane}
            handleClose={handleClosePane}
            modifiers={['unsetHeight']}
          >
            <SlidingPane.Content>
              <Container modifiers={'fluid'}>
                <Text modifiers={['medium']}>
                  Please select the status of the shipment as either sent by you
                  or received by the patient
                </Text>
              </Container>
              <Container modifiers={['withGutters']}>
                <Row
                  modifiers={['smallGutters']}
                  onClick={() => updateSelectedStatus('shipped')}
                >
                  <Checkbox
                    dark={true}
                    modifiers={['small', 'margin_1']}
                    checked={selectedStatus === 'shipped'}
                    onChange={() => {}}
                  />
                  <Column modifiers={'col'}>
                    <Text modifiers={['medium', 'block']}>Shipped</Text>
                  </Column>
                </Row>
                <Row
                  modifiers={['smallGutters']}
                  onClick={() => updateSelectedStatus('received')}
                >
                  <Checkbox
                    dark={true}
                    modifiers={['small', 'margin_1']}
                    checked={selectedStatus === 'received'}
                    onChange={() => {}}
                  />
                  <Column modifiers={'col'}>
                    <Text modifiers={['medium', 'block']}>Received</Text>
                  </Column>
                </Row>
              </Container>
            </SlidingPane.Content>
            <Container modifiers={['shadow']}>
              <Row modifiers={['spaceBetween', 'middle']}>
                <Button
                  modifiers={['icon', 'transparent']}
                  image={assets.icons.icCloseGray}
                  onClick={handleClosePane}
                />
                <Button
                  modifiers={['widthSmall', 'roundCorner']}
                  onClick={handleStatusUpdate}
                >
                  Done
                </Button>
              </Row>
            </Container>
          </SlidingPane>
          <Modal open={showShipmentModal} contained large>
            <ShipmentConfirm
              order={order}
              handleClose={() => setShowShipmentModal(false)}
              handleConfirm={handleConfirmShipment}
            />
          </Modal>
        </>
      )}
    </Container>
  );
};

export default OrderDetail;
