import { useContext, useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';

import { MainLayout } from 'layouts';
import {
  BackButton,
  Row,
  Column,
  Text,
  ScrollView,
} from '@gaz/gaz-components.public';
import { Container, PeriodPicker } from 'common';
import { ListItem } from './Item';
import { FETCH_ALERTS } from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';

const { Header, Content } = MainLayout;

export default () => {
  const history = useHistory();
  const { me } = useContext(AuthContext);
  const [alertsList, updateAlertsList] = useState(null);
  const [period, setPeriod] = useState('3m');
  const alertsListUpdateSubscription = useRef();
  const { subscribe } = useContext(SocketContext);
  const [queryAlerts] = useLazyQuery(FETCH_ALERTS, {
    variables: { period },
    onCompleted: (data) => {
      updateAlertsList(data.providerAlerts);
    },
    onError: () => {
      updateAlertsList([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchAlerts = useCallback(() => {
    updateAlertsList(null);
    queryAlerts();
  }, [queryAlerts]);

  useEffect(() => {
    if (!!period) {
      fetchAlerts();
      alertsListUpdateSubscription.current?.unsubscribe();
      alertsListUpdateSubscription.current = subscribe(
        EVENTS.ALERTS_UPDATE,
        () => {
          fetchAlerts();
        }
      );
    }
    return () => {
      alertsListUpdateSubscription.current?.unsubscribe();
    };
  }, [period, fetchAlerts, subscribe]);

  const goBack = () => {
    history.replace('/');
  };

  if (!me) {
    return null;
  }

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Text modifiers={['semiBold', 'start']}>
              {me.activeProviderPractice.practice.name}
            </Text>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        <Container
          modifiers={['fluid', 'fullHeight', 'flexBox', 'backgroundWhite']}
        >
          <Container modifiers={['backgroundGray']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Text modifiers={['large', 'bold']}>Alerts</Text>
              <Column>
                <PeriodPicker value={period} handleSelect={setPeriod} />
              </Column>
            </Row>
          </Container>
          <ScrollView>
            {alertsList?.length > 0 ? (
              <Container>
                {alertsList.map((alert) => (
                  <ListItem
                    key={alert.user._id}
                    alert={alert}
                    handleClick={() =>
                      history.replace(`/alerts/${alert.user._id}`)
                    }
                  />
                ))}
              </Container>
            ) : (
              <Text>No alerts found.</Text>
            )}
          </ScrollView>
        </Container>
      </Content>
    </MainLayout>
  );
};
