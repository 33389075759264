import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  small: ({ theme }) => ({
    styles: `
      font-size: ${theme.dimensions.fontSizeXSmall};
    `,
  }),
};

const styles = ({ theme }) => `
  color: ${theme.colors.danger};
  font-size: ${px2rem(12)};
  min-height: ${px2rem(16)};
  margin-top: ${px2rem(4)};
  font-family: ${theme.fonts.secondary} !important;
  display: flex;
  align-items: center;
  margin-left: ${px2rem(10)};
`;

export default buildStyledComponent('Form_Error', styled.div, styles, {
  modifierConfig,
});
