import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { loadData, saveData, removeData } from 'utils/storage';
import { useLazyQuery } from '@apollo/client';

import { PatientHomeContext } from '../../Context';
import { FETCH_PATIENT_CAREPLANS } from 'graphql/queries';

export const CreateCareplanContext = createContext();

const CreateCareplanProvider = ({ children }) => {
  const { provider, practice, patient, setBackHandler } =
    useContext(PatientHomeContext);
  const [newCareplan, setCareplan] = useState({
    content: {
      medication: null,
      vital: null,
      activity: null,
      wellness: null,
      diet: null,
      careTeam: null,
      notifications: null,
    },
  });
  const [footerVisible, setFooterVisible] = useState(true);
  const [itemsPickedPeriods, setItemsPickedPeriods] = useState({
    subjective: '6m',
    objective: '6m',
    plan: '6m',
    diagnosis: '6m',
    assessment: '6m',
    attachments: '6m',
  });

  const [activityList, updateActivityList] = useState();
  const [careplanHistory, updateCareplanHistory] = useState();

  const [fetchCareplans] = useLazyQuery(FETCH_PATIENT_CAREPLANS, {
    onCompleted: ({ patientCareplans: careplans }) => {
      updateCareplanHistory(careplans || []);
    },
  });

  const fetchPastCPs = useCallback(async () => {
    if (patient) {
      fetchCareplans({
        variables: {
          patient: patient._id,
          period: 'all',
          status: 'all',
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [patient]);

  useEffect(() => {
    if (patient) {
      const savedCPString = loadData(`careplanDraft-${patient._id}`);
      let savedCP;
      if (savedCPString?.length > 0) {
        savedCP = JSON.parse(savedCPString);
      } else {
        savedCP = newCareplan;
      }
      setCareplan({
        ...savedCP,
        user: patient._id,
      });
    }
  }, [patient]);

  useEffect(() => {
    fetchPastCPs();
  }, [fetchPastCPs]);

  const createCPBackHandler = (backHandler, force) => {
    if (!backHandler || backHandler === '') {
      setBackHandler('', force);
    } else {
      setBackHandler(async () => {
        await backHandler();
        return false;
      }, force);
    }
  };

  const checkEmptyCP = (cp) => {
    if (!cp.content) {
      return true;
    }
    const {
      medication,
      vital,
      activity,
      wellness,
      diet,
      careTeam,
      notifications,
    } = cp.content;

    return !medication && !vital && !activity && !wellness && !diet;
  };

  const saveLocalDraft = useCallback(
    (careplan) => {
      if (patient && careplan && JSON.stringify(careplan).length > 0) {
        if (checkEmptyCP(careplan)) {
          return;
        }
        saveData(`careplanDraft-${patient._id}`, JSON.stringify(careplan));
      }
    },
    [patient]
  );

  const removeLocalDraft = useCallback(() => {
    removeData(`careplanDraft-${patient._id}`);
  }, [patient]);

  const updateCareplan = useCallback(
    (cp, saveLocal = true) => {
      setCareplan(cp);
      if (saveLocal) {
        saveLocalDraft(cp);
      }
    },
    [saveLocalDraft]
  );

  const isEmptyCP = useMemo(() => checkEmptyCP(newCareplan), [newCareplan]);

  const fetchActivityList = (success, failure) => {
    // return useQuery();
  };

  const careplanHistorySigned = useMemo(() => {
    if (!careplanHistory) {
      return [];
    }
    return careplanHistory.filter((cp) => cp.signDate);
  }, [careplanHistory]);

  return (
    <CreateCareplanContext.Provider
      value={{
        setBackHandler: createCPBackHandler,
        careplan: newCareplan,
        updateCareplan,
        patient,
        provider,
        practice,
        footerVisible,
        setFooterVisible,
        isEmptyCP,
        itemsPickedPeriods,
        setItemsPickedPeriods,
        fetchActivityList,
        activityList,
        updateActivityList,
        removeLocalDraft,
        careplanHistoryAll: careplanHistory,
        careplanHistorySigned,
        fetchPastCPs,
      }}
    >
      {children}
    </CreateCareplanContext.Provider>
  );
};

export default CreateCareplanProvider;
