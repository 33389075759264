import assets from 'assets';
import urls from 'routes/urls';

export const OPTIONS = [
  {
    name: 'My Profile',
    icon: assets.icons.icPerson,
    route: urls.MY_PROFILE,
  },
  {
    name: (user) =>
      user.activeProviderPractice?.practice ? 'My Pharmacy' : 'Create Pharmacy',
    icon: assets.icons.icStethoscope,
    route: urls.MY_PRACTICE,
  },
  {
    name: 'Schedule',
    icon: assets.icons.icCalendar,
    route: urls.SCHEDULE,
  },
  {
    name: 'Call Masking',
    icon: assets.icons.icCall,
    route: urls.CALL_MASKING,
  },
  {
    name: 'Help Center',
    icon: assets.icons.icDoc,
    route: urls.HELP_CENTER,
  },
  {
    name: 'Security',
    icon: assets.icons.icShield,
    route: urls.SECURITY,
  },
  {
    name: 'Privacy Policy',
    icon: assets.icons.icPrivacy,
    route: urls.PRIVACY_POLICY,
  },
  {
    name: 'Terms and conditions',
    icon: assets.icons.icPaper,
    route: urls.TERMS,
  },
];
