import { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  Button,
  Column,
  Text,
  ScrollView,
  HR,
  Modal,
} from '@gaz/gaz-components.public';
import { useHistory } from 'react-router';

import { Container, Row } from 'common';
import NoteDetail from './Detail';
import ProviderSelect from './ProviderSelect';
import { displayTime } from 'utils/time';
import {
  formatTitleAndName,
  formatAddress,
  maskString,
  MASK_TYPE,
} from 'utils/string';
import { SHARE_NOTE, READ_PATIENT_NOTE } from 'graphql/mutations';
import { FETCH_NOTE } from 'graphql/queries';
import { loadingVar } from 'graphql/cache';
import assets from 'assets';
import { cloneDeep } from 'lodash';

const ViewNote = ({ note, goBack }) => {
  const history = useHistory();
  const { creator: provider, user: patient } = note;
  const practice =
    provider.activeProviderPractice?.practice || provider.practice;
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [fullyLoadedNote, updateFullyLoadedNote] = useState(note);
  const [shareNote] = useMutation(SHARE_NOTE);
  const [readNote] = useMutation(READ_PATIENT_NOTE);
  const [fetchNote] = useLazyQuery(FETCH_NOTE);
  const handleSelectProvider = async (provider) => {
    loadingVar(true);
    await shareNote({
      variables: {
        provider: provider._id,
        note: note._id,
      },
      onCompleted: () => {
        Modal.info({
          render: () => (
            <Text>
              Note shared with{' '}
              <Text modifiers={['primary']}>
                {formatTitleAndName(provider)}
              </Text>
            </Text>
          ),
        });
      },
    });
    loadingVar(false);
  };

  const goEditNote = () => {
    history.replace(`/patients/${note.user._id}/notes/create/${note._id}`);
  };

  const createNoteFromNote = () => {
    history.replace(
      `/patients/${note.user._id}/notes/createFromNote/${note._id}`
    );
  };

  useEffect(() => {
    if (note?._id && !note.isDraft && !note.isSeen) {
      readNote({ variables: { note: note._id } });
    }
    if (note.content.attachments) {
      let attachedNoteId;
      for (let attachment of note.content.attachments) {
        if (attachment.category === 'note' && !attachment.noteContent) {
          attachedNoteId = attachment.note;
          break;
        }
      }
      if (attachedNoteId) {
        loadingVar(true);
        fetchNote({
          variables: {
            id: attachedNoteId,
          },
          onCompleted: ({ note: noteAttachment }) => {
            loadingVar(false);
            if (noteAttachment) {
              const noteClone = cloneDeep(note);
              for (let attachment of noteClone.content.attachments) {
                if (attachment.category === 'note') {
                  attachment.noteContent = noteAttachment;
                  break;
                }
              }
              updateFullyLoadedNote({ ...noteClone });
            }
          },
        });
      }
    }
  }, [note]);

  return (
    <Container
      modifiers={['fluid', 'flexBox', 'fullHeight', 'backgroundWhite']}
    >
      <ScrollView>
        <Container modifiers={['padding_2']}>
          {note._id && (
            <Row modifiers={['smallGutters', 'end']}>
              <Text>Note: {note._id.substr(-7)}</Text>
            </Row>
          )}
          <Row modifiers={'smallGutters'}>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>{practice.name}</Text>
              <Text modifiers={['block', 'primary', 'medium']}>
                {formatTitleAndName(provider)}
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>
                {formatAddress(practice.address)}
              </Text>
            </Column>
          </Row>
          <Row modifiers={'smallGutters'}>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['medium', 'muted']}>
                NPI{' '}
                <Text modifiers={['primary', 'medium']}>{practice.npi}</Text>
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['medium', 'muted']}>
                PH{' '}
                <Text modifiers={['primary', 'medium']}>{practice.phone}</Text>
              </Text>
            </Column>
          </Row>
          <Row modifiers={'smallGutters'}>
            <Text modifiers={['medium', 'muted']}>
              Email:{' '}
              <Text modifiers={['primary', 'medium']}>{practice.email}</Text>
            </Text>
          </Row>
        </Container>
        <HR />
        <Container modifiers={['padding_2']}>
          <Row modifiers={'smallGutters'}>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>
                {formatTitleAndName(patient)}
              </Text>
              <Text modifiers={['medium', 'muted']}>
                DoB{' '}
                <Text modifiers={['primary', 'medium']}>
                  {displayTime(patient.dob, 'MM/DD/YYYY')}
                </Text>
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['block', 'medium']}>
                {formatAddress(patient.address)}
              </Text>
            </Column>
          </Row>
          <Row modifiers={'smallGutters'}>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['medium', 'muted']}>
                SS#{' '}
                <Text modifiers={['primary', 'medium']}>
                  {maskString(patient.ssn, MASK_TYPE.SSN)}
                </Text>
              </Text>
            </Column>
            <Column modifiers={['col_6', 'noPadding']}>
              <Text modifiers={['medium', 'muted']}>
                PH{' '}
                <Text modifiers={['primary', 'medium']}>
                  {patient.phones.mobile}
                </Text>
              </Text>
            </Column>
          </Row>
          <Row modifiers={'smallGutters'}>
            <Text modifiers={['medium', 'muted']}>
              Email:{' '}
              <Text modifiers={['primary', 'medium']}>{patient.email}</Text>
            </Text>
          </Row>
        </Container>
        <HR />
        <Container modifiers={['padding_2']}>
          <Text modifiers={['block', 'medium', 'muted']}>
            Visit date/time{' '}
            <Text modifiers={['medium', 'primary']}>
              {note.signDate &&
                displayTime(note.signDate, 'MM/DD/yyyy @ hh:mma')}
            </Text>
          </Text>
          <NoteDetail note={fullyLoadedNote} />
        </Container>
      </ScrollView>
      {note.signDate && goBack && (
        <Container modifiers={['footer', 'flexFixed']}>
          <Row modifiers={['spaceBetween']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['roundCorner', 'widthSmall']}
              onClick={createNoteFromNote}
            >
              Respond
            </Button>
            <Button
              modifiers={['roundCorner', 'widthSmall']}
              onClick={() => setShowSearchModal(true)}
            >
              Share
            </Button>
          </Row>
        </Container>
      )}
      {!note.signDate && goBack && (
        <Container modifiers={['footer', 'flexFixed']}>
          <Row modifiers={['spaceBetween']}>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icCloseGray}
              onClick={goBack}
            />
            <Button
              modifiers={['roundCorner', 'widthSmall']}
              onClick={goEditNote}
            >
              Edit
            </Button>
          </Row>
        </Container>
      )}
      {showSearchModal && (
        <Container modifiers={[!showSearchModal && 'noDisplay']}>
          <ProviderSelect
            handleClose={() => setShowSearchModal(false)}
            handleSelect={handleSelectProvider}
          />
        </Container>
      )}
    </Container>
  );
};

export default ViewNote;
