import {
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { useMutation, useLazyQuery } from '@apollo/client';

import { MainLayout } from 'layouts';
import {
  BackButton,
  Avatar,
  Row,
  Column,
  Text,
} from '@gaz/gaz-components.public';
import AlertsCategories from './PatientAlertsCategories';
import CategoryAlerts from './PatientCategoryAlerts';
import { FETCH_USER, FETCH_PATIENT_ALERTS } from 'graphql/queries';
import { BOOK_APPOINTMENT } from 'graphql/mutations';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';
import { loadingVar } from 'graphql/cache';
import { displayTime } from 'utils/time';
import urls from 'routes/urls';

const { Header, Content } = MainLayout;

export default () => {
  const history = useHistory();
  const { id: patientId } = useParams();
  const { me } = useContext(AuthContext);
  const [alertsList, updateAlertsList] = useState(null);
  const [period, setPeriod] = useState('3m');
  const alertsListUpdateSubscription = useRef();
  const { subscribe } = useContext(SocketContext);
  const [selectedCategory, updateSelectedCategory] = useState(null);
  const [queryAlerts] = useLazyQuery(FETCH_PATIENT_ALERTS, {
    variables: { patient: patientId, period },
    onCompleted: (data) => {
      updateAlertsList(data.patientAlerts);
    },
    onError: () => {
      updateAlertsList([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchAlerts = useCallback(() => {
    updateAlertsList(null);
    queryAlerts();
  }, [queryAlerts]);

  const [fetchUser, { data: { user: patient } = {} }] =
    useLazyQuery(FETCH_USER);

  useEffect(() => {
    if (!!period) {
      fetchAlerts();
      alertsListUpdateSubscription.current?.unsubscribe();
      alertsListUpdateSubscription.current = subscribe(
        EVENTS.ALERTS_UPDATE,
        () => {
          fetchAlerts();
        }
      );
    }
    return () => {
      alertsListUpdateSubscription.current?.unsubscribe();
    };
  }, [patient, period, fetchAlerts, subscribe]);

  const alertsCategories = useMemo(() => {
    const titles = ['vital', 'medication', 'activity', 'wellness', 'diet'];
    const categories = {};
    titles.forEach((title) => {
      categories[title] = {
        name: title,
        lastAlert: null,
        alerts: [],
        unseenCount: 0,
      };
    });
    alertsList?.forEach((alert) => {
      categories[alert.measure]?.alerts.push(alert);
      if (!categories[alert.measure].lastAlert) {
        categories[alert.measure].lastAlert = alert.triggerTime;
      }
      if (!alert.isSeen) {
        categories[alert.measure].unseenCount++;
      }
    });
    return Object.values(categories);
  }, [alertsList]);

  const [bookAppointment] = useMutation(BOOK_APPOINTMENT, {
    onCompleted: () => {
      goBack();
    },
  });

  const goBack = useCallback(() => {
    if (selectedCategory) {
      updateSelectedCategory(null);
    } else {
      history.replace(urls.ALERTS);
    }
  }, [selectedCategory]);

  const handleBook = async (time) => {
    loadingVar(true);
    await bookAppointment({
      variables: {
        appointment: {
          provider: me._id,
          patient: patientId,
          time,
        },
      },
    });
    loadingVar(false);
  };

  const handleAfterRead = (category) => {
    for (let alert of alertsList) {
      if (alert.measure !== category) {
        continue;
      }
      alert.isSeen = true;
    }
    updateAlertsList([...alertsList]);
  };

  useEffect(() => {
    if (!!patientId) {
      fetchUser({
        variables: { id: patientId },
      });
    }
  }, [patientId]);

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar
              user={patient}
              width={40}
              height={40}
              modifiers={['squared']}
            />
            <Column>
              <Row>
                <Text modifiers={['semiBold', 'start']}>
                  {getFullName(patient)}
                </Text>
              </Row>
              <Row>
                <Text modifiers={['primary', 'start']}>
                  {displayTime(patient?.dob, 'MM/DD/YY')}
                </Text>
              </Row>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        {me && patient && selectedCategory ? (
          <CategoryAlerts
            categoryAlerts={selectedCategory}
            handleBook={handleBook}
            me={me}
            patient={patient}
            handleAfterRead={handleAfterRead}
            goBack={goBack}
          />
        ) : (
          <AlertsCategories
            handleBook={handleBook}
            handleCategoryClick={(category) =>
              category.alerts?.length > 0 && updateSelectedCategory(category)
            }
            categories={alertsCategories}
            me={me}
            patient={patient}
            period={period}
            setPeriod={setPeriod}
            goBack={goBack}
          />
        )}
      </Content>
    </MainLayout>
  );
};
