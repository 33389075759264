import {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

import { FETCH_APPOINTMENTS } from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';
import { displayTime } from 'utils/time';

export const AppointmentServiceContext = createContext();

const AppointmentServiceProvider = ({ children, setTimerData }) => {
  // Starting appointments part
  const { me } = useContext(AuthContext);
  const { subscribe } = useContext(SocketContext);
  const appointmentUpdateSubscription = useRef();
  const appointmentsListUpdateSubscription = useRef();
  const [appointments, updateAppointments] = useState();
  const [fetchAppointments] = useLazyQuery(FETCH_APPOINTMENTS, {
    onCompleted: (data) => {
      if (data.appointments) {
        updateAppointments([...data.appointments]);
      }
    },
    fetchPolicy: 'no-cache',
  });
  const loadAppointments = useCallback(() => {
    const appointmentDate = new Date();
    const from = moment(
      displayTime(appointmentDate, 'MM/DD/YYYY'),
      'MM/DD/YYYY'
    );
    const to = from.clone();
    to.add(1, 'day');
    const params = { from: from.toDate(), to: to.toDate() };
    fetchAppointments({
      variables: params,
    });
  }, [fetchAppointments]);
  const checkStartingAppointmentRef = useRef();
  const [startingAppointments, updateStartingAppointments] = useState([]);

  const checkAppointmentCanStart = useCallback((appointment) => {
    if (appointment.status === 'active') {
      return true;
    }
    if (appointment.status === 'scheduled') {
      const startTime = moment(appointment.time).subtract(1, 'm');
      const secondsToStart = startTime.diff(moment(), 'seconds');
      if (secondsToStart <= 0) {
        return true;
      }
    }
    return false;
  }, []);

  const intervalRef = useRef(null);
  const firstTimeoutRef = useRef(null);

  const clearTimeouts = useCallback(() => {
    if (!!intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    if (!!firstTimeoutRef.current) {
      clearTimeout(firstTimeoutRef.current);
      firstTimeoutRef.current = null;
    }
  }, []);

  const firstTickHandler = useCallback(() => {
    firstTimeoutRef.current = null;
    if (checkStartingAppointmentRef.current) {
      checkStartingAppointmentRef.current();
    }
    intervalRef.current = setInterval(() => {
      if (checkStartingAppointmentRef.current) {
        checkStartingAppointmentRef.current();
      }
    }, 1000 * 60);
  }, []);

  useEffect(() => {
    appointmentUpdateSubscription.current = subscribe(
      EVENTS.APPOINTMENT_UPDATE,
      (payload) => {
        const { appointment: appointmentId, payload: updateData } = payload;
        const checkAppointment = appointments?.find(
          (item) => item._id === appointmentId
        );
        if (checkAppointment) {
          const newAppointments = appointments?.map((appointment) => {
            if (appointment._id === appointmentId) {
              if (updateData.joined === me._id) {
                if (!appointment.joined) {
                  appointment.joined = [];
                }
                appointment.joined.push(me._id);
              }
              return { ...appointment, status: updateData.status };
            }
            return appointment;
          });
          updateAppointments([...newAppointments]);
        }
      }
    );

    checkStartingAppointmentRef.current = () => {
      if (!appointments || appointments.length === 0) {
        updateStartingAppointments([]);
        return;
      }
      const sortedAppointments = [...appointments].sort((a, b) => {
        return new Date(b.time).getTime() - new Date(a.time).getTime();
      });
      const newList = [];
      for (let appointment of sortedAppointments) {
        if (checkAppointmentCanStart(appointment)) {
          newList.push(appointment);
        }
      }
      updateStartingAppointments(newList);
    };

    checkStartingAppointmentRef.current();

    return () => {
      appointmentUpdateSubscription.current?.unsubscribe();
    };
  }, [appointments, checkAppointmentCanStart, subscribe]);

  useEffect(() => {
    appointmentsListUpdateSubscription.current?.unsubscribe();
    if (!me) {
      appointmentsListUpdateSubscription.current = null;
    } else {
      appointmentsListUpdateSubscription.current = subscribe(
        EVENTS.APPOINTMENTS,
        () => {
          loadAppointments();
        }
      );
    }
  }, [loadAppointments, me, subscribe]);

  useEffect(() => {
    if (me) {
      loadAppointments();

      let firstTickDelay;
      const now = new Date().getTime();
      firstTickDelay = 60 * 1000 - (now % (60 * 1000));
      firstTimeoutRef.current = setTimeout(firstTickHandler, firstTickDelay);
    } else {
      appointmentsListUpdateSubscription.current?.unsubscribe();
      appointmentUpdateSubscription.current?.unsubscribe();
      appointmentsListUpdateSubscription.current = null;
      appointmentUpdateSubscription.current = null;
    }

    return () => {
      clearTimeouts();
    };
  }, [me]);

  return (
    <AppointmentServiceContext.Provider
      value={{
        startingAppointments,
      }}
    >
      {children}
    </AppointmentServiceContext.Provider>
  );
};

export default AppointmentServiceProvider;
