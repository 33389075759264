import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';

import { FETCH_CAREPLAN } from 'graphql/queries';
import { READ_PATIENT_CAREPLAN } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import CareplanDetail from 'pages/Careplans/CareplanDetail';
import { MainLayout } from 'layouts';
import {
  BackButton,
  Avatar,
  Row,
  Column,
  Text,
} from '@gaz/gaz-components.public';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';
import urls from 'routes/urls';

const { Header, Content } = MainLayout;

export default () => {
  const { me } = useContext(AuthContext);
  const history = useHistory();
  const { id } = useParams();
  const [readCareplan] = useMutation(READ_PATIENT_CAREPLAN, {
    variables: { careplan: id },
  });
  const { data: { careplan } = {}, loading } = useQuery(FETCH_CAREPLAN, {
    variables: { id },
  });

  useEffect(() => {
    if (careplan && !careplan.isDraft && !careplan.isSeen) {
      readCareplan();
    }
  }, [careplan]);

  const goBack = () => {
    history.replace(urls.CAREPLANS);
  };

  loadingVar(loading);

  if (loading || !me) {
    return null;
  }

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={goBack} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar user={me} width={40} height={40} modifiers={['squared']} />
            <Column modifiers={['col_8', 'flexBox']}>
              <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        <CareplanDetail careplan={careplan} goBack={goBack} noFooter />
      </Content>
    </MainLayout>
  );
};
