import { useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import styled from 'styled-components';

import {
  Column,
  ScrollView,
  SearchBar,
  Text,
} from '@gaz/gaz-components.public';

import assets from 'assets';
import { Row, OutsideClicker, Container } from 'common';
import { SEARCH_MEDICATIONS } from 'graphql/queries';
import { SEARCH_DEBOUNCE_TIME } from 'utils/constants';
import {
  buildStyledComponent,
  px2rem,
} from '@gaz/gaz-components.styled-builder';

const SearchMedication = ({ handleAdd, ...props }) => {
  const [searchMedications] = useLazyQuery(SEARCH_MEDICATIONS, {
    onCompleted: (data) => {
      setSearchResults(data.searchMedications);
      setShowList(true);
    },
  });
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState('');
  const [showList, setShowList] = useState(false);

  const handleSearch = useCallback(
    debounce((query) => {
      setQuery(query);
      setSearchResults([]);
      if (query.length < 3) {
        return;
      }
      searchMedications({
        variables: {
          query,
        },
        fetchPolicy: 'no-cache',
      });
    }, SEARCH_DEBOUNCE_TIME),
    []
  );

  const handleQueryChange = (value) => {
    handleSearch(value);
  };

  const handleClickOutside = () => {
    setShowList(false);
  };

  const handleAddMedication = (medication) => {
    setShowList(false);
    handleAdd(medication);
  };

  return (
    <Container {...props}>
      <OutsideClicker handleClickOutside={handleClickOutside}>
        <SearchBar
          onChange={handleQueryChange}
          autoFocus={true}
          placeholder={'Search and Add Medication'}
        />
        <Container
          modifiers={[
            'flexBox',
            'card',
            (query.length === 0 || !showList) && 'noDisplay',
          ]}
        >
          <ScrollView>
            {searchResults.length === 0 ? (
              <Row modifiers={['center', 'withGutters']}>
                <Text modifiers={['bold']}>No medications.</Text>
              </Row>
            ) : (
              searchResults.map((medication, index) => (
                <Row
                  onClick={() => handleAddMedication(medication)}
                  modifiers={['middle', 'smallGutters']}
                  key={index}
                >
                  <img src={assets.icons.icMedication} />
                  <Column modifiers={['col', 'noPadding']}>
                    <Text>{medication.name}</Text>
                  </Column>
                </Row>
              ))
            )}
          </ScrollView>
        </Container>
      </OutsideClicker>
    </Container>
  );
};

export default buildStyledComponent(
  'SearchMedication',
  styled(SearchMedication),
  ({ theme }) => `
    > :first-child {
      position: relative;
      > :last-child {
        background: ${theme.colors.white};
        max-height: ${px2rem(300)};
        border-radius: ${theme.dimensions.borderRadius_1};
        margin-top: ${px2rem(10)};
        position: absolute;
        z-index: 10;
      }
    }
  `
);
