import React from 'react';

import Wrapper from './Wrapper';
import Pharmacist from './Pharmacist';

export default function Summary({ patient, goOrders }) {
  return (
    <Wrapper>
      <Pharmacist patient={patient} goOrders={goOrders} />
    </Wrapper>
  );
}
