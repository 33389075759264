import React from 'react';
import Card from './Card';
import Wrapper from './Wrapper';
import Badge from './Badge';
import Circle from './Circle';
import { Text } from '@gaz/gaz-components.public';

export const Widget = ({
  icon,
  title,
  hasBadge,
  badgeCount,
  isFullWidth,
  ...props
}) => (
  <>
    {isFullWidth ? (
      <Wrapper {...props} modifiers={['fullWidth']}>
        <Card modifiers={['noCenter']}>
          <Circle>
            <img src={icon} alt="" style={{ margin: 'auto' }} />
          </Circle>

          <Text modifiers={['small', 'bold']} style={{ marginLeft: '10px' }}>
            {title}
          </Text>
        </Card>
      </Wrapper>
    ) : (
      <Wrapper {...props}>
        <Card>
          {hasBadge && <Badge>{badgeCount}</Badge>}
          <img
            style={{
              objectFit: 'contain',
              maxHeight: '100%',
              maxWidth: '100%',
            }}
            src={icon}
            alt=""
          />
        </Card>
        <Text modifiers={['small']}>{title}</Text>
      </Wrapper>
    )}
  </>
);
