import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { useMutation } from '@apollo/client';
import ImageViewer from 'react-simple-image-viewer';

import { Button, Avatar, Text } from '@gaz/gaz-components.public';
import { SIGN_S3 } from 'graphql/mutations';
import { errorVar, loadingVar } from 'graphql/cache';
import assets from 'assets';

import Wrapper from './Wrapper';
import AttachWrapper from './AttachWrapper';

const IMAGE_TYPES = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
];

const Input = styled.input`
  display: none;
`;

const TrashWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(20%) translateY(-20%);
`;

const NameWrapper = styled.div(
  ({ theme }) => `
    width: 100%;
    text-align: center;
    font-size: ${theme.dimensions.fontSizeMedium};
    margin-right: ${theme.dimensions.padding_1};
  `
);

export const FileInput = (props) => {
  const {
    modifiers,
    value,
    onChange,
    onBlur,
    children,
    width,
    height,
    addText,
    editText,
    disabled,
    contained,
    s3 = {},
    isAttach,
    onChangeAttachFile,
    attachRef,
    isCard,
    ...inputProps
  } = props;
  const hiddenFileInput = React.useRef(null);
  const [signS3] = useMutation(SIGN_S3);
  const [imageViewerIsOpen, setImageViewerIsOpen] = useState(false);
  let text = '';
  if (!disabled) {
    text = value?.url ? editText : addText;
  }

  const handleClick = (event) => {
    if (isCard && value?.url) return;

    if (!disabled) {
      hiddenFileInput.current.click();
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  const onResizeImage = async (file, fileType) => {
    let compressedFile = file;

    if (fileType.includes('image')) {
      compressedFile = await resizeFile(file);
    }

    return compressedFile;
  };

  const handleChange = async (event) => {
    const file = event.target.files[0];

    if (!file) return onBlur();

    const fileType = file.type;

    const {
      data: {
        signS3: { signedRequest, url },
      },
    } = await signS3({
      variables: {
        folder: s3.folder || 'temp',
        fileType,
      },
    });
    loadingVar(true);
    const compressedFile = await onResizeImage(file, fileType);

    axios
      .put(signedRequest, compressedFile, {
        headers: {
          'Content-Type': fileType,
        },
      })
      .then(() => {
        onChange({ originalName: file.name, type: file.type, url });
        loadingVar(false);
      })
      .catch((error) => {
        errorVar(error);
      })
      .then(() => {
        onBlur();
      });

    event.target.value = null;
  };

  const renderPreview = () => {
    if (!value) return null;

    if (IMAGE_TYPES.includes(value.type)) {
      return (
        <div style={{ position: 'relative', width: '100%' }}>
          <Avatar
            modifiers={[modifiers.includes('round') ? 'round' : '']}
            width={width}
            height={height}
            image={value.url}
            style={{ backgroundSize: contained ? 'contain' : 'cover' }}
            onClick={() => isCard && setImageViewerIsOpen(true)}
          />
          {!disabled && (
            <TrashWrapper>
              <Button
                modifiers={['white', 'icon', 'round', 'noPadding']}
                image={assets.icons.icCloseRed}
                onClick={handleDelete}
                width={30}
                height={30}
                imageWidth={30}
                imageHeight={30}
              />
            </TrashWrapper>
          )}
        </div>
      );
    } else {
      return (
        <>
          <NameWrapper>{value?.originalName}</NameWrapper>
          {!disabled && (
            <Button
              modifiers={['light', 'icon']}
              image={assets.icons.trash}
              onClick={handleDelete}
            />
          )}
        </>
      );
    }
  };

  // todo: remove file in s3
  const handleDelete = (ev) => {
    ev.stopPropagation();
    onChange(null);
  };

  const handleChangeAttachFile = async (e) => {
    const file = e.target.files[0];
    const isImageFile = file.type.includes('image');
    const compressedFile = await onResizeImage(file, file.type);

    const attachmentOption = {
      url: URL.createObjectURL(file),
      type: file.type || 'image',
      file: isImageFile ? compressedFile : file,
    };

    onChangeAttachFile(attachmentOption);
  };

  if (isAttach) {
    return (
      <AttachWrapper
        onChange={handleChangeAttachFile}
        type="file"
        ref={attachRef}
      ></AttachWrapper>
    );
  } else {
    return (
      <>
        {imageViewerIsOpen ? (
          <ImageViewer
            src={[value.url]}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={() => setImageViewerIsOpen(false)}
          />
        ) : (
          <Wrapper modifiers={modifiers} width={width} onClick={handleClick}>
            {!value?.url && <div>{children || <Button>Upload</Button>}</div>}
            {value?.url && renderPreview()}
            {text && <Text modifiers={['primary', 'topPadding']}>{text}</Text>}

            <Input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              {...inputProps}
              capture
            />
          </Wrapper>
        )}
      </>
    );
  }
};

export default FileInput;
