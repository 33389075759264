import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {
  fullWidth: ({ theme }) => `
    width: 100%;
    padding: 0 5%;
  `,
};

const styles = ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export default buildStyledComponent('Widget_Wrapper', styled.div, styles, {
  modifierConfig,
});
