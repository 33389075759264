import { useContext, useEffect } from 'react';

import { PatientHomeContext } from './Context';
import PharmacyOrders from 'pages/Pharmacy';

const Orders = () => {
  const { patient, setBackHandler, goBack } = useContext(PatientHomeContext);

  useEffect(() => {
    setBackHandler('');
  }, []);

  return patient ? (
    <PharmacyOrders
      patient={patient}
      setBackHandler={setBackHandler}
      goBack={goBack}
    />
  ) : null;
};

export default Orders;
