import React, { useEffect, useContext, useState } from 'react';
import { Container } from '@gaz/gaz-components.public';

import { PatientHomeContext } from '../Context';
import Dashboard from './Dashboard';
import CareplanDetail from 'pages/Careplans/CareplanDetail';

const Careplans = () => {
  const { goBack, setBackHandler } = useContext(PatientHomeContext);
  const [selectedCareplan, setSelectedCareplan] = useState(null);

  const showCareplan = (careplan) => {
    setBackHandler(() => {
      setSelectedCareplan(null);
      return false;
    });
    setSelectedCareplan(careplan);
  };

  useEffect(() => {
    setBackHandler('');
  }, []);

  return (
    <Container modifiers={['fluid', 'fullHeight']}>
      <Dashboard showCareplan={showCareplan} inactive={!!selectedCareplan} />
      {selectedCareplan && (
        <CareplanDetail
          careplan={selectedCareplan}
          goBack={goBack}
          goEdit={goBack}
        />
      )}
    </Container>
  );
};

export default Careplans;
