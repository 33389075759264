import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';

import {
  Container,
  Row,
  Column as Col,
  Button,
  A,
} from '@gaz/gaz-components.public';
import { FormPasswordInput, FormInput, Text, FormPinInput } from 'common';
import urls from 'routes/urls';
import schema from 'utils/schema';
import { loadData } from 'utils/storage';
import { LOGIN } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { AuthContext } from 'contexts/auth';

const LOGIN_TYPES = {
  PIN: 'pin',
  PASSWORD: 'password',
};
const loginSchema = Yup.object().shape({
  email: schema.email,
  password: schema.password,
});
const pinSchema = Yup.object().shape({
  pin: schema.pin,
});
const initialValues = {
  email: '',
  password: '',
};
const pinInitialValues = {
  pin: '',
};

export default function Login({ location, history }) {
  const { me, updateAuth } = useContext(AuthContext);
  const [loginType, setLoginType] = useState(LOGIN_TYPES.PASSWORD);
  const email = loadData('email');

  useEffect(() => {
    if (email) {
      setLoginType(LOGIN_TYPES.PIN);
    }
  }, [email]);

  const [login] = useMutation(LOGIN, {
    onCompleted: async (data) => {
      const { login } = data;

      if (login) {
        const { user, token } = login;
        await updateAuth(user, token);
        history.replace(location.state?.from || '/');
      } else {
      }
    },
    onError: () => {},
  });

  if (me) {
    return <Redirect to={urls.DASHBOARD} />;
  }

  const handleLogin = async (values) => {
    loadingVar(true);
    const timezoneOffset = -new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isProvider = false;

    if (loginType === LOGIN_TYPES.PASSWORD) {
      await login({
        variables: {
          ...values,
          role: 'provider',
          timezoneOffset,
          timezone,
          isProvider,
        },
      });
    } else {
      await login({
        variables: {
          email,
          pin: values.pin,
          role: 'provider',
          timezoneOffset,
          timezone,
          isProvider,
        },
      });
    }

    loadingVar(false);
  };

  const renderInputs = () => {
    switch (loginType) {
      case LOGIN_TYPES.PIN:
        return (
          <>
            <Row>
              <Col modifiers={['col_12']}>
                <Field
                  fields={4}
                  autoFocus={false}
                  name="pin"
                  placeholder="-"
                  component={FormPinInput}
                />
              </Col>
            </Row>
          </>
        );
      case LOGIN_TYPES.PASSWORD:
      default:
        return (
          <>
            <Row>
              <Col modifiers={['col_12']}>
                <Field
                  name="email"
                  placeholder="Enter email address"
                  label="Email"
                  type="email"
                  hasLabel
                  largeLabel
                  modifiers={['squared']}
                  component={FormInput}
                />
              </Col>
            </Row>
            <Row>
              <Col modifiers={['col_12']}>
                <Field
                  name="password"
                  placeholder="Enter password"
                  label="Password"
                  component={FormPasswordInput}
                />
              </Col>
            </Row>
          </>
        );
    }
  };

  return (
    <Formik
      initialValues={
        loginType === LOGIN_TYPES.PASSWORD ? initialValues : pinInitialValues
      }
      validationSchema={
        loginType === LOGIN_TYPES.PASSWORD ? loginSchema : pinSchema
      }
      onSubmit={handleLogin}
    >
      {({ isValid, dirty }) => (
        <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Container modifiers={['flexBox', 'fullHeight']}>
            {renderInputs()}
            <Row modifiers={['withGutters']}>
              <Col modifiers={['col_12', 'flexBox', 'center']}>
                {email && (
                  <Text
                    onClick={() =>
                      setLoginType(
                        loginType === LOGIN_TYPES.PASSWORD
                          ? LOGIN_TYPES.PIN
                          : LOGIN_TYPES.PASSWORD
                      )
                    }
                    modifiers={['h3', 'darkGrey', 'center', 'topPadding']}
                  >
                    {loginType === LOGIN_TYPES.PASSWORD
                      ? 'Login via Pin Code'
                      : 'Login via Password'}
                  </Text>
                )}
              </Col>
            </Row>
            <Row>
              <Col modifiers={['col_12', 'flexBox', 'center']}>
                <Button
                  modifiers={[
                    'primary',
                    'roundCorner',
                    'widthMedium',
                    (!dirty || !isValid) && 'disabled',
                  ]}
                  type="submit"
                >
                  Login
                </Button>
              </Col>
            </Row>
            <Row>
              <Col modifiers={['col_12', 'flexBox', 'center']}>
                <Button modifiers={['link']}>Forgot your password?</Button>
              </Col>
            </Row>
            <Row
              style={{ marginTop: 'auto' }}
              modifiers={['center', 'withGutters']}
            >
              <Text modifiers={['xSmall']}>By continuing you agree to</Text>
              <A to={urls.TERMS} modifiers={['xSmall']}>
                Gazuntite's Condition of Use
              </A>
              <Text modifiers={['xSmall']}>and</Text>
              <A to={urls.PRIVACY_POLICY} modifiers={['xSmall']}>
                Privacy Policy
              </A>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
}
