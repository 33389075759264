import moment from 'moment';

export const displayTime = (timestamp, format = null) => {
  if (!timestamp) {
    return null;
  }
  let timeFormat;
  if (format === 'date') {
    timeFormat = 'MM/DD/YYYY';
  } else if (!!format) {
    timeFormat = format;
  } else {
    timeFormat = 'MM/DD/YYYY h:mm A';
  }
  return moment(timestamp).format(timeFormat);
};

export const msToDuration = () => {};

export const secondsToDuration = (seconds, shortForm = false) => {
  let sec = seconds % 60;
  if (sec < 10) {
    sec = '0' + sec;
  }

  if (!shortForm) {
    let min = Math.floor(seconds / 60) % 60;
    const hour = Math.floor(seconds / 3600);

    if (min < 10) {
      min = '0' + min;
    }
    return `${hour}:${min}:${sec}`;
  }

  let min = Math.floor(seconds / 60);
  if (min < 10) {
    min = '0' + min;
  }
  return `${min}:${sec}`;
};

export const formatLastSeen = (time) => {
  return time ? moment(time).format('MM/DD/YYYY') : 'N/A';
};

export const toGMT = (time, dateOnly = false, returnObject = false) => {
  const a = moment(time).utcOffset(0, true);
  const b = dateOnly
    ? moment.utc(a.format('ll'))
    : moment.utc(a.format('YYMMDD HH:mm:ss'), 'YYMMDD HH:mm:ss');
  return returnObject ? b : b.toISOString();
};

export const careplanDates = (careplan, format = 'MM/DD/YY') => {
  if (!careplan) {
    return [null, null];
  }
  const startDate = toLocalTime(careplan.startDate);
  const endDate = startDate.clone().add(careplan.duration, 'days');
  if (format === false) {
    return [startDate, endDate];
  }
  return [startDate.format(format), endDate.format(format)];
};

export const toLocalTime = (
  time,
  format = 'object',
  timezoneOffset,
  dateOnly = false
) => {
  const offset = timezoneOffset || moment(time).utcOffset();
  const localTime = moment(time).subtract(offset, 'm');
  if (dateOnly) {
    localTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }
  if (format === 'object') {
    return localTime;
  } else if (format === 'datetime') {
    return localTime.format('YYMMDD hh:mm:ss');
  } else if (format === 'time') {
    return localTime.format('hh:mm:ss');
  } else if (format === 'date') {
    return localTime.format('MM/DD/YYYY');
  }
  return localTime.format(format);
};

export const hourToDate = (time, referenceDate = null) => {
  if (!referenceDate) {
    referenceDate = moment();
  }
  const newDate = moment(time, 'hh:mm A').utcOffset(
    referenceDate.utcOffset(),
    true
  );
  newDate.set({
    year: referenceDate.year(),
    month: referenceDate.month(),
    date: referenceDate.date(),
  });
  return newDate;
};

export const checkTaskDue = (time) => {
  const taskTime = hourToDate(time);
  return taskTime.isBefore(new Date());
};

export const getStartDate = (period = 'w', date = null) => {
  const startDate = new Date(date || new Date());
  switch (period) {
    case 'w':
      const dayOfWeek = startDate.getDay();
      if (dayOfWeek > 0) {
        startDate.setDate(startDate.getDate() - dayOfWeek);
      }
      return startDate;
    case 'm':
      startDate.setDate(1);
      return startDate;
    case 'y':
      startDate.setDate(1);
      startDate.setMonth(0);
      return startDate;
  }
  return startDate;
};
