import {
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';

import { MainLayout } from 'layouts';

import {
  BackButton,
  Avatar,
  Row,
  Column,
  Text,
  ScrollView,
  SearchBar,
} from '@gaz/gaz-components.public';
import { Container, PeriodPicker, Tabs } from 'common';
import ListItem from './ListItem';
import { FETCH_PATIENT_CAREPLANS } from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';
import { getFullName } from 'utils/string';

const { Header, Content } = MainLayout;

export default () => {
  const history = useHistory();
  const { me } = useContext(AuthContext);
  const [tab, setTab] = useState(0);
  const [careplans, setCareplans] = useState(null);
  const [period, setPeriod] = useState('3m');
  const { subscribe } = useContext(SocketContext);
  const careplanListUpdateSubscription = useRef();

  const [queryCareplans] = useLazyQuery(FETCH_PATIENT_CAREPLANS, {
    variables: {
      period,
    },
    onCompleted: (data) => {
      setCareplans(data.patientCareplans);
    },
    onError: () => {
      setCareplans([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchCareplans = useCallback(() => {
    setCareplans(null);
    queryCareplans();
  }, []);

  useEffect(() => {
    if (!!period) {
      fetchCareplans();
      careplanListUpdateSubscription.current?.unsubscribe();
      careplanListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_CAREPLANS,
        () => {
          fetchCareplans();
        }
      );
    }
    return () => {
      careplanListUpdateSubscription.current?.unsubscribe();
    };
  }, [period, fetchCareplans]);

  const activeCP = useMemo(() => {
    if (careplans?.length > 0) {
      return careplans.filter((careplan) => careplan.isActive);
    }
    return [];
  }, [careplans]);

  const draftCPs = useMemo(() => {
    if (careplans?.length > 0) {
      return careplans.filter((careplan) => careplan.isDraft);
    }
    return [];
  }, [careplans]);

  const expiredCPs = useMemo(() => {
    if (careplans?.length > 0) {
      return careplans.filter(
        (careplan) => !careplan.isDraft && !careplan.isActive
      );
    }
    return [];
  }, [careplans]);

  const cps = tab === 0 ? activeCP : tab === 1 ? expiredCPs : draftCPs;

  if (!me) {
    return null;
  }

  return (
    <MainLayout>
      <Header>
        <Header.Left>
          <BackButton onClick={() => history.replace('/')} />
        </Header.Left>
        <Header.Center>
          <Row modifiers={['middle']}>
            <Avatar user={me} width={40} height={40} modifiers={['squared']} />
            <Column modifiers={['col_8', 'flexBox']}>
              <Text modifiers={['semiBold', 'start']}>{getFullName(me)}</Text>
            </Column>
          </Row>
        </Header.Center>
      </Header>
      <Content>
        <Container
          modifiers={['fluid', 'fullHeight', 'flexBox', 'backgroundWhite']}
        >
          <Container modifiers={['flexFixed', 'backgroundGray']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Text modifiers={['medium', 'bold']}>Care Plans List</Text>
              <Column>
                <PeriodPicker value={period} handleSelect={setPeriod} />
              </Column>
            </Row>
            <Tabs current={tab} onChange={setTab} flatTabs>
              <Tabs.Tab title="Active" />
              <Tabs.Tab title="Expired" />
              <Tabs.Tab title="Drafts" />
            </Tabs>
          </Container>
          <ScrollView>
            <Container>
              {cps?.length > 0 ? (
                cps.map((careplan, i) => (
                  <ListItem careplan={careplan} key={i} />
                ))
              ) : (
                <Text modifiers={['muted']}>No care plans found.</Text>
              )}
            </Container>
          </ScrollView>
        </Container>
      </Content>
    </MainLayout>
  );
};
