import styled from 'styled-components';

import { buildStyledComponent } from 'style';

const modifierConfig = {};

const styles = ({ theme }) => `
  width: 35px;
  height: 35px;
  background-color: #eaf3fc;
  border-radius: 50%;
  display: flex;
  margin-left: 10px;
`;

export default buildStyledComponent('Circle', styled.span, styles, {
  modifierConfig,
});
