import { useState, createRef, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';

import {
  Container,
  Row,
  TimePicker,
  Checkbox,
  Button,
  Text,
  WeekdaysPicker,
} from 'common';
import { Directions, CustomRow, CustomField, WeeklyTimeItem } from './Items';
import { displayTime, hourToDate } from 'utils/time';

const FrequencyTypes = ['Daily', 'Weekly'];
const WeeksOptions = Array(10)
  .fill()
  .map((_, index) => {
    return {
      id: `${index + 1}`,
      name: `${index + 1}`,
    };
  });
const DirectionOptions = [...Directions];
DirectionOptions[0] = { id: 'none', shortName: 'SELECT', name: 'NONE' };
const DailyTimeOptions = {
  AM: 'morning (AM)',
  noon: 'noon',
  PM: 'night (PM)',
  bed: 'bed',
};

const CustomFrequency = ({ value, innerRef }) => {
  const quantityRef = createRef();
  const initialValues = {
    quantity: value?.quantity || 1,
    frequency: value?.frequency || 'Weekly',
    everyWeeks: value?.everyWeeks || '1',
    weeklyTimes: value?.weeklyTimes || [],
    weeklyDirection: value?.weeklyDirection || 'none',
    weekDays: value?.weekDays || [],
    everyDays: value?.everyDays || 1,
    startDate: value?.startDate || new Date(),
    dailyTime: value?.dailyTime || [],
    dailyDirection: value?.dailyDirection || 'none',
  };
  const [frequencyType, setFrequencyType] = useState('Weekly');
  const [selectedWeekDays, setSelectedWeekdays] = useState([]);
  const [selectedWeeklyTimes, updateSelectedWeeklyTimes] = useState({});
  const [selectedDailyTimes, setSelectedDailyTimes] = useState({});
  const [dailyTimeString, setDailyTimeString] = useState('');
  const addTimePickerRef = createRef();
  const [timePickerValue, setTimePickerValue] = useState();

  const toggleWeekdaySelection = (day, selected) => {
    let updatedDays = [...selectedWeekDays];
    if (selected) {
      updatedDays.push(day);
      updatedDays.sort();
    } else {
      updatedDays = selectedWeekDays.filter((elem) => elem !== day);
    }
    setSelectedWeekdays([...updatedDays]);
    innerRef.current.setFieldValue('weekDays', updatedDays);
  };

  const toggleDailyTimeSelection = (key) => {
    selectedDailyTimes[key]
      ? delete selectedDailyTimes[key]
      : (selectedDailyTimes[key] = true);
    setDailyTimeString(
      Object.keys(DailyTimeOptions)
        .filter((key) => selectedDailyTimes[key])
        .join(', ')
    );
    setSelectedDailyTimes({ ...selectedDailyTimes });
    innerRef.current.setFieldValue(
      'dailyTime',
      Object.keys(selectedDailyTimes)
    );
  };

  useEffect(() => {
    setFrequencyType(value?.frequency || 'Weekly');
    if (value?.weekDays?.length > 0) {
      setSelectedWeekdays([...value.weekDays]);
    } else {
      setSelectedWeekdays([]);
    }
    if (value?.weeklyTimes?.length > 0) {
      const times = {};
      value.weeklyTimes.forEach((time) => (times[time] = true));
      setSelectedWeeklyTimes(times);
    } else {
      setSelectedWeeklyTimes({});
    }
    if (value?.dailyTime?.length > 0) {
      const dailyTimes = {};
      value.dailyTime.forEach((time) => {
        dailyTimes[time] = true;
      });
      setDailyTimeString(value.dailyTime.join(', '));
      setSelectedDailyTimes(dailyTimes);
    }
  }, [value]);

  const onClickAddTime = useCallback(() => {
    setTimePickerValue([true]);
  });
  const handleAddWeeklyTime = useCallback(
    (time) => {
      if (!!timePickerValue && timePickerValue[0] !== true) {
        delete selectedWeeklyTimes[timePickerValue[0]];
      }
      selectedWeeklyTimes[time] = true;
      setSelectedWeeklyTimes({ ...selectedWeeklyTimes });
      setTimePickerValue(null);
    },
    [selectedWeeklyTimes, timePickerValue]
  );
  useEffect(() => {
    if (!addTimePickerRef || !timePickerValue) {
      return;
    }
    addTimePickerRef.current.click();
  }, [addTimePickerRef, timePickerValue]);

  const setSelectedWeeklyTimes = useCallback(
    (times) => {
      if (!times || Object.keys(times).length === 0) {
        innerRef?.current?.setFieldValue('weeklyTimes', {});
        updateSelectedWeeklyTimes([]);
        return;
      }
      const hourObjects = Object.keys(times).map((time) =>
        hourToDate(time).toDate()
      );
      hourObjects.sort();
      const selectedTimes = {};
      hourObjects.forEach(
        (time) => (selectedTimes[displayTime(time, 'hh:mm A')] = true)
      );
      innerRef?.current?.setFieldValue(
        'weeklyTimes',
        Object.keys(selectedTimes)
      );
      updateSelectedWeeklyTimes(selectedTimes);
    },
    [innerRef]
  );

  const handleRemoveWeeklyTime = useCallback(
    (time) => {
      delete selectedWeeklyTimes[time];
      setSelectedWeeklyTimes({ ...selectedWeeklyTimes });
    },
    [selectedWeeklyTimes]
  );

  const handleClickWeeklyTime = useCallback((time) => {
    setTimePickerValue([time]);
  }, []);

  if (!value) {
    return null;
  }

  return (
    <>
      <Formik initialValues={initialValues} innerRef={innerRef}>
        <Form>
          <CustomRow
            title="Quantity"
            onClick={() => {
              quantityRef.current.focus();
            }}
          >
            <CustomField name="quantity" innerRef={quantityRef} type="number" />
          </CustomRow>
          <CustomRow title="Frequency">
            <CustomField
              fieldType="picker"
              placeholder="SELECT FREQUENCY"
              name="frequency"
              options={FrequencyTypes}
              handleChange={(value) => {
                setFrequencyType(value);
              }}
            />
          </CustomRow>
          {frequencyType === 'Weekly' && (
            <Container modifiers={['fluid']}>
              <CustomRow title="Every" hasThreeComponents>
                <CustomField
                  fieldType="picker"
                  name="everyWeeks"
                  options={WeeksOptions}
                />
                <Text modifiers={['blue']}>Week</Text>
              </CustomRow>
              <WeekdaysPicker
                handleSelect={toggleWeekdaySelection}
                selectedDays={selectedWeekDays}
                twoDayStyle
              />
              <CustomRow title="Time(s)" noBorder>
                <Button
                  modifiers={['roundCorner', 'medium', 'outlinePrimary']}
                  onClick={onClickAddTime}
                >
                  + ADD TIME
                </Button>
              </CustomRow>
              {Object.values(selectedWeeklyTimes).length > 0 && (
                <Row modifiers={['withGutters']}>
                  {Object.keys(selectedWeeklyTimes).map((time) => (
                    <WeeklyTimeItem
                      key={time}
                      value={time}
                      handleRemove={handleRemoveWeeklyTime}
                      handleClick={handleClickWeeklyTime}
                    />
                  ))}
                </Row>
              )}
              <CustomRow title="Direction">
                <CustomField
                  fieldType="picker"
                  name="weeklyDirection"
                  options={DirectionOptions}
                />
              </CustomRow>
            </Container>
          )}
          {frequencyType === 'Daily' && (
            <Container modifiers={['fluid']}>
              <CustomRow title="Every" hasThreeComponents>
                <CustomField name="everyDays" />
                <Text modifiers={['blue']}>Day(s)</Text>
              </CustomRow>
              <CustomRow title="Start Date">
                <CustomField fieldType="datePicker" name="startDate" />
              </CustomRow>
              <CustomRow title="Time">
                <Text>{dailyTimeString}</Text>
              </CustomRow>
              <Container modifiers={['fluid', 'paddingLeft']}>
                {Object.keys(DailyTimeOptions).map((key) => (
                  <Row
                    key={key}
                    modifiers={['withGutters']}
                    onClick={() => {
                      toggleDailyTimeSelection(key);
                    }}
                  >
                    <Checkbox
                      dark={true}
                      modifiers={['small']}
                      checked={!!selectedDailyTimes[key]}
                      onChange={() => {}}
                    />
                    <Text>{DailyTimeOptions[key]}</Text>
                  </Row>
                ))}
              </Container>
              <CustomRow title="Direction">
                <CustomField
                  fieldType="picker"
                  name="dailyDirection"
                  options={DirectionOptions}
                />
              </CustomRow>
            </Container>
          )}
        </Form>
      </Formik>
      <TimePicker handleChange={handleAddWeeklyTime} value="08:00 AM">
        <Container modifiers={['noDisplay']} ref={addTimePickerRef}></Container>
      </TimePicker>
    </>
  );
};

export default CustomFrequency;
