import { startCase } from 'lodash';
import { MEMBER_DESIGNATIONS } from './constants';

export const MASK_TYPE = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  SSN: 'SSN',
};

export const getFullName = (user) => {
  if (!user) {
    return '';
  }

  return startCase(
    [user.firstName, user.lastName].filter((n) => !!n).join(' ')
  );
};

export const formatMemberDesignation = (key) => {
  const value = MEMBER_DESIGNATIONS[key];

  return value || key;
};

export const formatTitleAndName = (userOrProvider) => {
  const user = userOrProvider.user ? userOrProvider.user : userOrProvider;
  if (!user) {
    return '';
  }
  if (!user.role) {
    return user.name;
  }
  if (user.role === 'patient') {
    return getFullName(user);
  }
  return (
    getFullName(user) +
    (user.memberDesignation
      ? `, ${formatMemberDesignation(user.memberDesignation)}`
      : '')
  );
};

export const formatAddress = (address) => {
  if (address) {
    return `${address.addressLine1}, ${address.city}, ${address.state}, ${address.zipcode}`;
  }

  return '';
};

export const maskString = (value, type) => {
  if (!value) {
    return '';
  }

  switch (type) {
    case MASK_TYPE.PHONE:
      return `******${value.substr(-4)}`;
    case MASK_TYPE.EMAIL:
      const parts = value.split('@');
      return `${parts[0].substr(0, 2)}***@${parts[1]}`;
    case MASK_TYPE.SSN:
      if (!value || value.length < 10) {
        return 'XXX-XX-XXXX';
      }
      return `XXX-XX-${value.substr(-4)}`;
    default:
      return '';
  }
};

export const formatPhoneNumber = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }
  if (cvLength > 10) {
    return currentValue;
  }
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

export const chatTitle = (members, me) => {
  const otherMembers = members.filter((m) => m._id !== me._id);

  const title = otherMembers.map((m) => getFullName(m)).join(', ');

  return title;
};
