import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  Container,
  Row,
  Column,
  VR,
  Text,
  Button,
} from '@gaz/gaz-components.public';

import { PatientHomeContext } from '../../Context';
import { buildStyledComponent } from 'style';
import ActiveCareplanAddTimeSection from './ActiveCareplanAddTimeSection';
import AddResponse from '../AddResponse';
import Progress from '../Progress';
import { careplanDates } from 'utils/time';

const Component = ({ expandSection, handleViewPlan, ...props }) => {
  const [showTimeSection, setShowTimeSection] = useState(false);
  const [showResponseSection, setShowResponseSection] = useState(false);
  const [showProgressSection, setShowProgressSection] = useState(false);
  const { activeCareplan, setBackHandler, goBack } =
    useContext(PatientHomeContext);
  const [startDate, endDate] = careplanDates(activeCareplan);

  useEffect(() => {
    expandSection(
      showTimeSection || showResponseSection || showProgressSection
    );
  }, [showTimeSection, showResponseSection, showProgressSection]);

  const toggleTimeSection = () => {
    setShowResponseSection(false);
    setShowProgressSection(false);
    if (showTimeSection) {
      goBack();
    } else {
      setBackHandler(() => {
        setShowTimeSection(false);
        return false;
      }, true);
      setShowTimeSection(true);
    }
  };

  const toggleResponseSection = () => {
    setShowTimeSection(false);
    setShowProgressSection(false);
    if (showResponseSection) {
      goBack();
    } else {
      setBackHandler(() => {
        setShowResponseSection(false);
        return false;
      }, true);
      setShowResponseSection(true);
    }
  };

  const toggleProgressSection = () => {
    if (showProgressSection) {
      handleViewPlan();
    } else {
      setShowTimeSection(false);
      setShowResponseSection(false);
      setShowProgressSection(true);
      setBackHandler(() => {
        setShowProgressSection(false);
        return false;
      }, true);
    }
  };

  return (
    <Container modifiers={['fluid', 'fullHeight', 'flex']} {...props}>
      <Row modifiers={['spaceBetween']}>
        <Column modifiers={['noPadding', 'col_4']}>
          <Text
            modifiers={['block', 'start', 'medium', 'mediumWeight', 'dark']}
          >
            30-day time
          </Text>
          {!activeCareplan ? (
            <Text modifiers={['block', 'center', 'small']}>...</Text>
          ) : (
            <Text modifiers={['block', 'start', 'topPadding', 'small']}>
              01:49:59
            </Text>
          )}
          <VR modifiers={['padding_1']} />
        </Column>
        <Column modifiers={['noPadding', 'col_3']}>
          <Text
            modifiers={['block', 'start', 'mediumWeight', 'dark', 'medium']}
          >
            Duration
          </Text>
          {!activeCareplan ? (
            <Text modifiers={['block', 'center', 'small']}>...</Text>
          ) : (
            <Text modifiers={['block', 'start', 'topPadding', 'small']}>
              {activeCareplan.duration} days
            </Text>
          )}
          <VR modifiers={['padding_1']} />
        </Column>
        <Column modifiers={['noPadding', 'col_5']}>
          <Text
            modifiers={['block', 'start', 'mediumWeight', 'dark', 'medium']}
          >
            CP period
          </Text>
          {!activeCareplan ? (
            <Text modifiers={['block', 'center', 'small']}>...</Text>
          ) : (
            <Text modifiers={['block', 'start', 'topPadding', 'small']}>
              {startDate} <Text modifiers={['small', 'noPadding']}>to</Text>{' '}
              {endDate}
            </Text>
          )}
        </Column>
      </Row>
      <Row modifiers={['spaceBetween']}>
        <Column modifiers={['center', 'col_4', 'flexBox']}>
          <Button
            modifiers={[
              showTimeSection ? 'primary' : 'outlinePrimary',
              'large',
              'noShadow',
              'normalFontSize',
              'fullWidth',
              'roundCorner',
            ]}
            onClick={toggleTimeSection}
          >
            + TIME
          </Button>
        </Column>
        <Column modifiers={['center', 'col_4', 'flexBox']}>
          <Button
            modifiers={[
              showResponseSection ? 'danger' : 'outlineDanger',
              'large',
              'noShadow',
              'normalFontSize',
              'fullWidth',
              'roundCorner',
              !activeCareplan && 'disabled',
            ]}
            onClick={toggleResponseSection}
            disabled={!activeCareplan}
          >
            + RESPONSE
          </Button>
        </Column>
        <Column modifiers={['center', 'col_4', 'flexBox']}>
          <Button
            modifiers={[
              'outlineSuccess',
              'large',
              'noShadow',
              'normalFontSize',
              'fullWidth',
              'roundCorner',
              !activeCareplan && 'disabled',
            ]}
            onClick={toggleProgressSection}
            disabled={!activeCareplan}
          >
            {showProgressSection ? 'View Plan' : 'Progress'}
          </Button>
        </Column>
      </Row>
      {showTimeSection && <ActiveCareplanAddTimeSection />}
      {showResponseSection && <AddResponse />}
      {showProgressSection && <Progress />}
    </Container>
  );
};

const ActiveCareplanSection = buildStyledComponent(
  'ActiveCareplanSection',
  styled(Component),
  ({ theme }) => `
    font-size: ${theme.dimensions.fontSizeMedium};
    text-align: center;
    background: ${theme.colors.white};
    padding: ${theme.dimensions.padding_1};
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    > :last-child {
      flex: 1;
    }
  `
);

export default ActiveCareplanSection;
