import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_PATIENT_ORDERS } from 'graphql/queries';
import { Row, Column, Text, ScrollView } from '@gaz/gaz-components.public';

import { Container, Loader, PeriodPicker } from 'common';
import { PatientHomeContext } from 'pages/PatientHome/Context';
import { EVENTS, SocketContext } from 'contexts/socket';
import Item from './Item';

const MedicationOrders = ({ handleViewMedication }) => {
  const { patient, records, setRecords } = useContext(PatientHomeContext);
  const [fetchOrders, { loading }] = useLazyQuery(FETCH_PATIENT_ORDERS, {
    onCompleted: ({ patientMedicationOrders: orders }) => {
      const newOrders = records.medications;
      newOrders.list = orders;
      setRecords({ ...records, medication: newOrders });
    },
    fetchPolicy: 'no-cache',
  });
  const { subscribe } = useContext(SocketContext);
  const orderSubscription = useRef();

  const handleSelectPeriod = (value) => {
    const medications = records.medications;
    if (value !== medications.period) {
      medications.period = value;
      medications.list = [];
      setRecords({ ...records, medications });
      if (!!patient) {
        fetchPastOrders(value);
      }
    }
  };

  const fetchPastOrders = (period = null) => {
    fetchOrders({
      variables: {
        patient: patient._id,
        period: period || records.medications.period,
      },
    });
  };

  const ordersUpdateEventHandler = useCallback(() => {
    fetchPastOrders();
  }, [fetchPastOrders]);

  useEffect(() => {
    orderSubscription.current?.unsubscribe();
    orderSubscription.current = subscribe(
      EVENTS.PATIENT_MEDICATION_ORDERS,
      (payload) => {
        if (payload?.patient === patient?._id) {
          ordersUpdateEventHandler();
        }
      }
    );
    return () => {
      orderSubscription.current?.unsubscribe();
    };
  }, [ordersUpdateEventHandler, patient]);

  useEffect(() => {
    if (!patient) {
      return;
    }
    fetchPastOrders();
  }, [patient]);

  const orders = records.medications.list || [];

  return (
    <Container modifiers={['flexBox', 'fullHeight']}>
      <Container modifiers={['flexFixed']}>
        <Row modifiers={['spaceBetween', 'middle']}>
          <Text modifiers={['medium', 'bold']}>
            Ordered Medications{orders.length > 0 ? ` (${orders.length})` : ''}
          </Text>
          <Column>
            <PeriodPicker
              value={records.medications.period}
              handleSelect={handleSelectPeriod}
            />
          </Column>
        </Row>
      </Container>
      <ScrollView>
        {loading ? (
          <Row modifiers={['center', 'withGutters']}>
            <Loader.Icon />
          </Row>
        ) : orders.length > 0 ? (
          <Container>
            {orders.map((order, index) => (
              <Item
                key={index}
                order={order}
                handleClick={() => handleViewMedication(order)}
              />
            ))}
          </Container>
        ) : (
          <Text>No medications found.</Text>
        )}
      </ScrollView>
    </Container>
  );
};

export default MedicationOrders;
