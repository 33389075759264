import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Row, Column, VR, Text } from '@gaz/gaz-components.public';
import { useLazyQuery } from '@apollo/client';

import urls from 'routes/urls';
import Card from './Card';
import { FETCH_PHARMACY_SUMMARY } from 'graphql/queries';
import { EVENTS, SocketContext } from 'contexts/socket';

export default function Summary({ patient, goOrders: goPatientOrders }) {
  const history = useHistory();
  const goOrders = () => {
    if (patient === false) {
      return;
    }
    if (patient) {
      goPatientOrders();
    } else {
      history.push(urls.PHARMACY_ORDERS);
    }
  };
  const [pharmacySummary, updatePharmacySummary] = useState();
  const [fetchSummary] = useLazyQuery(FETCH_PHARMACY_SUMMARY, {
    variables: {
      patient: patient?._id,
    },
    onCompleted: (data) => {
      updatePharmacySummary(data?.pharmacySummary);
    },
    onError: () => {},
    fetchPolicy: 'no-cache',
  });
  const { subscribe } = useContext(SocketContext);
  const orderSubscription = useRef();

  const ordersUpdateEventHandler = useCallback(() => {
    fetchSummary();
  }, []);

  useEffect(() => {
    fetchSummary();

    orderSubscription.current = subscribe(
      EVENTS.MEDICATION_ORDERS,
      ordersUpdateEventHandler
    );
    return () => {
      orderSubscription.current?.unsubscribe();
    };
  }, []);

  return (
    <>
      <Row modifiers={['spaceBetween']}>
        <Text modifiers={['h3', 'block']}>MEDICATION ORDERS</Text>
        <Text modifiers={['body', 'block']}>{moment().format('MMM YYYY')}</Text>
      </Row>
      <Card>
        <Row modifiers={['spaceBetween']} onClick={goOrders}>
          <Column modifiers={['noVerticalPadding', 'col_4']}>
            <Text modifiers={['small', 'body', 'white', 'block']}>Orders</Text>
            <Text modifiers={['h3', 'white']}>
              {pharmacySummary ? pharmacySummary.orders : '-'}
            </Text>
            <VR modifiers={['height_95%']} />
          </Column>
          <Column modifiers={['noVerticalPadding', 'col_4']}>
            <Text modifiers={['small', 'body', 'white', 'block']}>
              Processing
            </Text>
            <Text modifiers={['h3', 'white']}>
              {pharmacySummary ? pharmacySummary.processing : '-'}
            </Text>
          </Column>
          <Column modifiers={['noVerticalPadding', 'col_4', 'center']}>
            <VR modifiers={['height_95%', 'left']} />
            <Text modifiers={['small', 'body', 'white', 'block']}>Status</Text>
            <Text modifiers={['h3', 'white']}>
              {pharmacySummary ? pharmacySummary.status : '-'}
            </Text>
          </Column>
        </Row>
      </Card>
    </>
  );
}
