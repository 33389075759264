import assets from 'assets';
import EntryItem from '../Entry';

const Item = ({ medication, noAlerts, ...props }) => {
  if (!medication) {
    return null;
  }
  return (
    <EntryItem
      icon={assets.icons.icMedication}
      text={`${medication.name}, ${medication.dosageForm}`}
      frequency={medication.frequency}
      mods={medication.mods}
      noAlerts
      {...props}
    />
  );
};

export default Item;
