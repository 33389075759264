import {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Row, Container } from '@gaz/gaz-components.public';

import { Switch, Text, Modal } from 'common';
import { GET_PHONE_VERIFICATION_STATUS } from 'graphql/queries';
import { INIT_VERIFY_NUMBER, UPDATE_CALL_MASKING } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import { SimpleLayout } from 'layouts';
import { AuthContext } from 'contexts/auth';
import { ProviderContext } from 'contexts/provider';
import Phone from './Phone';
import assets from 'assets';
import { formatTitleAndName } from 'utils/string';
import { EVENTS, SocketContext } from 'contexts/socket';
import { formatPhoneNumber } from 'utils/string';

const PHONE_TYPES = ['work', 'mobile', 'home'];

export default ({}) => {
  const history = useHistory();
  const { me } = useContext(AuthContext);
  const { callMasking, updateCallMasking } = useContext(ProviderContext);
  const [verificationStatus, updateVerificationStatus] = useState({});
  const [fetchQuery] = useLazyQuery(GET_PHONE_VERIFICATION_STATUS, {
    onCompleted: (data) => {
      updateVerificationStatus({ ...data.phoneVerifyStatus });
    },
    fetchPolicy: 'no-cache',
  });
  const [initVerifyApi] = useMutation(INIT_VERIFY_NUMBER);
  const [updateMaskingApi] = useMutation(UPDATE_CALL_MASKING);
  const [phones, updatePhones] = useState({});
  const [masking, updateMasking] = useState();
  const [maskingToggle, setMaskingToggle] = useState(null);
  const [verificationCode, setVerificationCode] = useState();
  const modalRef = useRef;
  const selectedTypeRef = useRef();

  const providerName = useMemo(() => {
    if (!me) {
      return '';
    }
    return formatTitleAndName(me);
  }, [me]);
  const practiceName = useMemo(() => {
    if (!me) {
      return '';
    }
    return me.activeProviderPractice.practice?.name;
  }, [me]);

  const requestVerify = useCallback(
    async (type) => {
      loadingVar(true);
      await initVerifyApi({
        variables: { number: phones[type] },
        onCompleted: (data) => {
          setVerificationCode(data.initVerifyNumber);
        },
      });
      loadingVar(false);
    },
    [initVerifyApi, phones]
  );

  const goBack = useCallback(() => {
    history.replace('/');
  }, []);

  const handleVerify = useCallback(
    (type) => {
      selectedTypeRef.current = type;
      requestVerify(type);
    },
    [requestVerify]
  );

  const handleSelect = useCallback(
    (type) => {
      if (!verificationStatus[type]) {
        Modal.info({
          text: 'Please verify the number first.',
        });
      } else {
        updateMasking(type);
      }
    },
    [verificationStatus]
  );

  const handleDone = async () => {
    if (maskingToggle && !masking) {
      Modal.info({
        text: 'Please select one of the number',
      });
      return;
    }
    const maskingValue = maskingToggle ? masking : 'off';
    loadingVar(true);
    await updateMaskingApi({
      variables: { masking: maskingValue },
    });
    updateCallMasking(maskingValue === 'off' ? null : maskingValue);
    loadingVar(false);
    goBack();
  };

  const { subscribe } = useContext(SocketContext);
  const verificationSubscription = useRef();

  useEffect(() => {
    if (!phones || Object.keys(phones).length === 0) {
      return;
    }
    if (verificationCode) {
      if (verificationCode === 'verified') {
        const formattedNumber = formatPhoneNumber(
          phones[selectedTypeRef.current]
        );
        let verificationUpdated = false;
        PHONE_TYPES.forEach((type) => {
          if (formatPhoneNumber(phones[type]) === formattedNumber) {
            verificationUpdated = true;
            verificationStatus[type] = true;
          }
        });
        if (verificationUpdated) {
          updateVerificationStatus({ ...verificationStatus });
        }
        selectedTypeRef.current = null;
      } else {
        modalRef.current = Modal.confirm({
          title: 'Verfication Code',
          render: () => (
            <Container>
              <Row modifiers={['center']}>
                <Text modifiers={['medium']}>
                  We will call your number. Please input below code.
                </Text>
              </Row>
              <Row modifiers={['withGutters', 'center']}>
                <Text modifiers={['large', 'primary']}>{verificationCode}</Text>
              </Row>
            </Container>
          ),
          okText: 'Retry',
          onCancel: 'Cancel',
          onOk: () => {
            modalRef.current = null;
            requestVerify(selectedTypeRef.current);
          },
          onCancel: () => {
            modalRef.current = null;
            selectedTypeRef.current = null;
          },
        });
      }
    }
  }, [verificationCode, phones]);

  useEffect(async () => {
    if (!me) {
      return;
    }
    updatePhones({
      ...me.phones,
      work: me.activeProviderPractice.practice.phone,
    });
    updateMasking(callMasking);
    if (maskingToggle === null) {
      setMaskingToggle(!!callMasking);
    }
    loadingVar(true);
    await fetchQuery();
    loadingVar(false);
  }, [me]);

  useEffect(async () => {
    verificationSubscription.current?.unsubscribe();
    verificationSubscription.current = subscribe(
      EVENTS.PHONE_VERIFICATION,
      (payload) => {
        if (!phones) {
          return;
        }
        const { number, status } = payload;
        const formattedNumber = formatPhoneNumber(number);
        let verificationUpdated = false;

        if (modalRef.current && selectedTypeRef.current) {
          if (
            formattedNumber ===
            formatPhoneNumber(phones[selectedTypeRef.current])
          ) {
            modalRef.current.destroy();
            modalRef.current = null;
            if (status !== 'success') {
              modalRef.current = Modal.confirm({
                title: 'Verfication Failed',
                render: () => (
                  <Container>
                    <Row modifiers={['center']}>
                      <Text modifiers={['medium']}>
                        <Text modifiers={['medium', 'primary']}>
                          {formattedNumber}
                        </Text>{' '}
                        verification failed.
                      </Text>
                    </Row>
                  </Container>
                ),
                okText: 'Retry',
                onCancel: 'Cancel',
                onOk: () => {
                  modalRef.current = null;
                  requestVerify(selectedTypeRef.current);
                },
                onCancel: () => {
                  modalRef.current = null;
                  selectedTypeRef.current = null;
                },
              });
            } else {
              selectedTypeRef.current = null;
            }
          }
        }
        if (status === 'success') {
          PHONE_TYPES.forEach((type) => {
            if (formatPhoneNumber(phones[type]) === formattedNumber) {
              verificationUpdated = true;
              verificationStatus[type] = true;
            }
          });
          if (verificationUpdated) {
            updateVerificationStatus({ ...verificationStatus });
          }
        }
      }
    );

    return () => {
      verificationSubscription.current?.unsubscribe();
    };
  }, [verificationStatus, phones]);

  if (!me) {
    return null;
  }

  return (
    <SimpleLayout>
      <SimpleLayout.Header content="Call Masking"></SimpleLayout.Header>
      <SimpleLayout.Content>
        <Container
          modifiers={['backgroundWhite', 'fullHeight', 'flexBox', 'padding_2']}
        >
          <Row modifiers={['withGutters', 'middle', 'spaceBetween']}>
            <Text modifiers={['bold', 'block', '']}>Call Masking</Text>
            <Switch active={maskingToggle} onChange={setMaskingToggle} />
          </Row>
          {maskingToggle &&
            PHONE_TYPES.map((type) =>
              !!phones[type] ? (
                <Phone
                  type={type}
                  number={phones[type]}
                  key={type}
                  name={providerName}
                  practice={practiceName}
                  selected={masking === type}
                  verified={verificationStatus[type]}
                  handleVerify={() => handleVerify(type)}
                  handleSelect={() => handleSelect(type)}
                />
              ) : null
            )}
        </Container>
      </SimpleLayout.Content>
      <SimpleLayout.Footer>
        <Row modifiers={['middle', 'spaceBetween']}>
          <Button
            modifiers={['icon', 'transparent']}
            image={assets.icons.icCloseGray}
            onClick={goBack}
          />
          <Button
            modifiers={['widthSmall', 'roundCorner']}
            onClick={handleDone}
          >
            Done
          </Button>
        </Row>
      </SimpleLayout.Footer>
    </SimpleLayout>
  );
};
