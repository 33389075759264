import styled from 'styled-components';
import { Avatar, Row, Column, HR, Container } from '@gaz/gaz-components.public';

import { Text } from 'common';
import { formatMemberDesignation } from 'utils/string';
import { displayTime } from 'utils/time';
import { buildStyledComponent, px2rem } from 'style';
import { alertsTitle, frequencyTitle, medicationModsTitle } from 'utils/helper';

export const ListItem = ({ order, handleClick }) => {
  let statusString;
  switch (order.status) {
    case 'sent':
      statusString = 'Sent to PMS';
      break;
    case 'shipped':
      statusString = 'SHIPPED';
      break;
    case 'received':
      statusString = 'RECEIVED';
      break;
  }
  return (
    <Container modifiers={['fluid']}>
      <Row modifiers={['middle']} onClick={handleClick}>
        <Column modifiers={['col_2']}>
          <Avatar
            modifiers={['round']}
            user={order.provider}
            width="40"
            height="40"
          />
        </Column>
        <Column modifiers={['col_4']}>
          <Text modifiers={['block']}>{order.provider.firstName}</Text>
          <Text modifiers={['block']}>
            {order.provider.lastName},{' '}
            {formatMemberDesignation(order.provider.memberDesignation)}
          </Text>
        </Column>
        {order.status === 'placed' ? (
          <Column modifiers={['col_6']}>
            <Text modifiers={['block', 'primary', 'end', 'medium']}>
              {displayTime(order.createdAt, 'MM/DD/YY')}
            </Text>
            <Text modifiers={['block', 'primary', 'end', 'medium']}>
              @ {displayTime(order.createdAt, 'hh:mm a')}
            </Text>
          </Column>
        ) : (
          <Column modifiers={['col_6']}>
            <Text modifiers={['block', 'end', 'medium']}>{statusString}</Text>
            <Text modifiers={['block', 'primary', 'end', 'medium']}>
              {`${displayTime(order.createdAt, 'MM/DD/YY')} @ ${displayTime(
                order.createdAt,
                'hh:mm a'
              )}`}
            </Text>
          </Column>
        )}
      </Row>
      <HR modifiers={['width_95%']} />
    </Container>
  );
};

const InfoRow = ({ title, value }) => (
  <Row>
    <Column modifiers={['col_4', 'noPadding']}>
      <Text modifiers={['medium', 'bold']}>{title}</Text>
    </Column>
    <Column modifiers={['col', 'noPadding']}>
      <Text modifiers={['medium', 'primary']}>{value}</Text>
    </Column>
  </Row>
);

export const MedicationItem = buildStyledComponent(
  'MedicationItem',
  styled(({ medication, index, ...props }) => {
    const frequencyTitles = frequencyTitle(medication.frequency, true);
    return (
      <Container modifiers={['fluid']} {...props}>
        <Row>
          <Text
            modifiers={['primary', 'medium']}
          >{`${index}. ${medication.name}`}</Text>
        </Row>
        <Container>
          <InfoRow title="freq:" value={frequencyTitles.frequency} />
          <InfoRow title="mods:" value={medicationModsTitle(medication.mods)} />
          <InfoRow title="dir:" value={frequencyTitles.direction} />
          {frequencyTitles.on && (
            <InfoRow title="on:" value={frequencyTitles.on} />
          )}
          <InfoRow title="@:" value={frequencyTitles.at} />
          <InfoRow title="quantity:" value={medication.quantity} />
        </Container>
      </Container>
    );
  }),
  ({ theme }) => `
    > :last-child {
      padding: ${px2rem(8)} ${px2rem(20)};
    }
  `
);
