import { useContext, useEffect, useMemo } from 'react';

import {
  Text,
  ContentCard,
  Row,
  Column,
  Button,
} from '@gaz/gaz-components.public';

import { Container } from 'common';
import assets from 'assets';
import { getMeasureProgress } from 'utils/careplan';
import { PROGRESS_ITEMS } from 'utils/constants';
import { PatientHomeContext } from 'pages/PatientHome/Context';

const Item = ({ type, progress, handleSelect }) => {
  const isNA = progress === null || progress === undefined;

  const handleClick = () => {
    handleSelect(PROGRESS_ITEMS[type].url);
  };
  return (
    <ContentCard modifiers={['withGutters', 'fluid']}>
      <Row modifiers={['middle']} onClick={handleClick}>
        <Column modifiers={['col', 'flexBox', 'verticalContentCenter']}>
          <img src={PROGRESS_ITEMS[type].icon} />
          <Text modifiers={['darkGrey', 'subtitle', 'semiBold']}>
            {PROGRESS_ITEMS[type].title}
          </Text>
        </Column>
        <Column>
          <Row modifiers={['middle', 'spaceBetween']}>
            <Text
              modifiers={[
                isNA ? 'muted' : progress > 60 ? 'success' : 'danger',
                'subtitle',
                'semiBold',
              ]}
            >
              {!isNA ? `${Math.round(progress)}%` : 'N/A'}
            </Text>
            <Button
              modifiers={['icon', 'transparent']}
              image={assets.icons.icGoNextBig}
              width={32}
              height={32}
            />
          </Row>
        </Column>
      </Row>
    </ContentCard>
  );
};

export default ({ handleItemSelect }) => {
  const { progress, fetchProgress } = useContext(PatientHomeContext);

  const progressValues = useMemo(() => {
    const measureProgress = {
      vital: null,
      medication: null,
      activity: null,
      wellness: null,
      diet: null,
      // appointment: null,
    };
    if (!!progress) {
      Object.keys(measureProgress).map((measure) => {
        measureProgress[measure] = getMeasureProgress(progress, measure);
      });
    }
    return measureProgress;
  }, [progress]);

  useEffect(() => {
    if (progress === null || progress === undefined) {
      fetchProgress();
    }
  }, []);

  return (
    <Container modifiers={['fullHeight', 'backgroundWhite']}>
      {Object.keys(progressValues).map((measure) => (
        <Item
          progress={progressValues[measure]}
          type={measure}
          key={measure}
          handleSelect={handleItemSelect}
        />
      ))}
    </Container>
  );
};
