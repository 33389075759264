import { useState, useCallback, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  Row,
  ScrollView,
  Text,
  Button,
  BackButton,
} from '@gaz/gaz-components.public';

import { Container, Modal, Popover } from 'common';
import MedicationItem from '../Careplans/CreateCareplan/Medications/Item';
import CartConfirm from './CartConfirm';
import assets from 'assets';
import { PatientHomeContext } from '../Context';
import { loadingVar } from 'graphql/cache';
import { ORDER_MEDS } from 'graphql/mutations';

const MedsCart = ({ medications, updateMedications, handleClose }) => {
  const [deletingMedication, setDeletingMedication] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { goBack, patient, provider } = useContext(PatientHomeContext);
  const [createOrder] = useMutation(ORDER_MEDS, {
    onCompleted: () => {
      goBack();
    },
    onError: () => {},
  });

  useEffect(() => {
    if (Object.values(medications).length === 0) {
      handleClose();
    }
  }, [medications]);

  const onRemove = useCallback((medication) => {
    setShowDeleteModal(true);
    setDeletingMedication(medication);
  }, []);

  const onConfirmDelete = () => {
    delete medications[deletingMedication.ndc];
    setShowDeleteModal(false);
    updateMedications({ ...medications });
  };

  const handleShowConfirm = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmOrder = async () => {
    loadingVar(true);
    await createOrder({
      variables: {
        medications: Object.values(medications),
        patient: patient._id,
      },
    });
    loadingVar(false);
  };

  return (
    <Modal open>
      <Modal.Header>
        <Row modifiers={['middle']}>
          <BackButton onClick={handleClose} />
          <Text>Med Order Cart</Text>
        </Row>
      </Modal.Header>
      <Modal.Body modifiers={['noMargin', 'textLeft']}>
        <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
          <ScrollView>
            {Object.entries(medications).map(([ndc, medication]) => (
              <MedicationItem
                medication={medication}
                key={ndc}
                noAlerts
                onRemove={() => {
                  onRemove(medication);
                }}
              />
            ))}
          </ScrollView>
          <Container modifiers={['flexFixed', 'shadow', 'backgroundWhite']}>
            <Row modifiers={['spaceBetween', 'middle']}>
              <Button
                image={assets.icons.icCloseGray}
                modifiers={['icon', 'transparent']}
                onClick={handleClose}
              />
              <Button
                modifiers={['widthMedium', 'roundCorner']}
                onClick={handleShowConfirm}
              >
                Send Order
              </Button>
            </Row>
          </Container>
        </Container>
        <Popover
          open={showDeleteModal}
          onConfirm={onConfirmDelete}
          onClose={() => setShowDeleteModal(false)}
          footer
        >
          <Text modifiers={['block', 'center']}>
            Are you sure want to delete this medication?
          </Text>
        </Popover>
        <Modal open={showConfirmModal} contained large>
          <CartConfirm
            medications={medications}
            updateMedications={updateMedications}
            handleClose={() => setShowConfirmModal(false)}
            handleConfirm={handleConfirmOrder}
          />
        </Modal>
      </Modal.Body>
    </Modal>
  );
};

export default MedsCart;
