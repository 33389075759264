import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/client';

import { splashLoadingVar, loadingVar } from 'graphql/cache';
import { FETCH_ME } from 'graphql/queries';
import { saveData, removeData, loadData } from 'utils/storage';
import IdleTimer from 'utils/IdleTimer';
import urls from 'routes/urls';

import { SocketContext } from './socket';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [me, setMe] = useState(null);
  const [loading, setLoading] = useState(true);
  const { openSocket, closeSocket } = useContext(SocketContext);
  const idleTimer = useRef(null);

  const updateAuth = async (user, token) => {
    if (!user) {
      removeData('token');
      closeSocket();
    } else if (token) {
      saveData('token', token);
      saveData('email', user?.email);
      openSocket(token);
    }

    setMe(user);
  };

  const [fetchMe] = useLazyQuery(FETCH_ME, {
    onCompleted: async (data) => {
      setMe(data.me);
      if (data.me) {
        const token = loadData('token');
        openSocket(token);
      }
      setLoading(false);

      setTimeout(() => {
        splashLoadingVar(false);
      }, 500); // give 500ms delay to prevent quirks
    },
    onError: () => {
      setLoading(false);
      setTimeout(() => {
        splashLoadingVar(false);
      }, 500); // give 500ms delay to prevent quirks
    },
  });

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      removeData('token'); // remove token on initial launch
    }
    splashLoadingVar(true);
  }, []);

  useEffect(() => {
    const resetLogin = async () => {
      await updateAuth();
      history.replace(urls.AUTH);
    };

    if (me) {
      if (!idleTimer.current) {
        idleTimer.current = new IdleTimer({
          timeout: 10 * 60,
          onTimeout: () => {
            resetLogin();
          },
          onExpired: () => {
            resetLogin();
          },
        });
      }
    } else {
      if (idleTimer.current) {
        idleTimer.current.cleanUp();
        idleTimer.current = null;
      }
    }
  }, [me, history]);

  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  loadingVar(loading);

  return (
    <AuthContext.Provider
      value={{
        me,
        loading,
        updateAuth,
        fetchMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
