import { useState, useEffect, createRef } from 'react';

import { Container, ScrollView } from '@gaz/gaz-components.public';
import { Tabs } from 'common';
import { DefaultPresets, PresetItem } from './Presets';
import CustomFrequency from './CustomFrequency';

const FrequencyEdit = ({ frequency, getRef, icon }) => {
  const [activeType, setActiveType] = useState(frequency?.type || 'preset');
  const [activeTab, setActiveTab] = useState(
    frequency?.type === 'custom' ? 1 : 0
  );
  const [presets, setPresets] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [presetRefs, setPresetRefs] = useState([]);
  const [customFrequency, setCustomFrequency] = useState();
  const customFrequencyRef = createRef();

  useEffect(() => {
    setPresetRefs(
      Array(DefaultPresets.length)
        .fill()
        .map(() => createRef({}))
    );
    const initPresets = [...DefaultPresets];
    let customFreq = {};
    if (frequency?.type === 'preset') {
      const slotCounts = frequency.value.hours.length;
      setSelectedIndex(slotCounts - 1);
      setActiveType('preset');
      Object.assign(initPresets[slotCounts - 1], {
        slots: frequency.value.hours,
        direction: frequency.value.direction,
      });
    } else if (frequency?.type === 'custom') {
      setActiveType('custom');
      customFreq = frequency.value;
    }
    setPresets(initPresets);
    setCustomFrequency(customFreq);
  }, []);

  getRef.current = () => {
    if (activeType === 'preset') {
      const frequency = {};
      const hours = [];
      const formValues = presetRefs[selectedIndex].current.values;
      Object.keys(formValues).forEach((key) => {
        if (key.startsWith('slot')) {
          hours.push(formValues[key]);
        } else {
          frequency[key] = formValues[key];
        }
      });
      frequency.hours = hours;
      return {
        type: activeType,
        value: frequency,
      };
    }
    const customFormValues = { ...customFrequencyRef.current.values };
    if (customFormValues.frequency === 'Daily') {
      delete customFormValues['everyWeeks'];
      delete customFormValues['weeklyTimes'];
      delete customFormValues['weeklyDirection'];
      delete customFormValues['weekDays'];
    } else {
      delete customFormValues['everyDays'];
      delete customFormValues['startDate'];
      delete customFormValues['dailyTime'];
      delete customFormValues['dailyDirection'];
    }
    return {
      type: activeType,
      value: customFormValues,
    };
  };

  const changeActiveType = (type) => {
    if (type === 'custom') {
      const newPresets = [];
      presetRefs.forEach((ref, index) => {
        const formValues = ref.current.values;
        const slots = [];
        for (let i = 0; i <= index; i++) {
          slots.push(formValues[`slot${i}`]);
        }
        newPresets.push({
          times: index + 1,
          title: formValues.title,
          slots,
          direction: formValues.direction,
        });
      });
      setPresets(newPresets);
    } else {
      setCustomFrequency(customFrequencyRef.current.values);
    }
    setActiveType(type);
  };

  const selectTab = (tab) => {
    setActiveTab(tab);
    changeActiveType(tab === 0 ? 'preset' : 'custom');
  };

  return (
    <Container modifiers={['fluid', 'flexBox', 'fullHeight']}>
      <Tabs current={activeTab} onChange={selectTab} noSeperator>
        <Tabs.Tab title="Preset" modifiers={['xSmallPadding', 'noTab']} />
        <Tabs.Tab title="Custom" modifiers={['xSmallPadding']} />
      </Tabs>
      <ScrollView>
        {activeType === 'preset' && (
          <Container>
            {presets.map((preset, index) => (
              <Container modifiers={['fluid']} key={index}>
                <PresetItem
                  icon={icon}
                  preset={preset}
                  hasDirection
                  handleSelection={() => setSelectedIndex(index)}
                  selected={index === selectedIndex}
                  formRef={presetRefs[index]}
                />
              </Container>
            ))}
          </Container>
        )}
        {activeType === 'custom' && (
          <Container>
            <CustomFrequency
              value={customFrequency}
              innerRef={customFrequencyRef}
            />
          </Container>
        )}
      </ScrollView>
    </Container>
  );
};

export default FrequencyEdit;
