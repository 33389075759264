import {
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import { Container } from 'common';
import { FETCH_PATIENT_ALERTS } from 'graphql/queries';
import { BOOK_APPOINTMENT } from 'graphql/mutations';
import { EVENTS, SocketContext } from 'contexts/socket';
import { AuthContext } from 'contexts/auth';
import AlertsCategories from 'pages/Alerts/PatientAlertsCategories';
import CategoryAlerts from 'pages/Alerts/PatientCategoryAlerts';
import { loadingVar } from 'graphql/cache';
import { PatientHomeContext } from '../Context';

export default () => {
  const { me } = useContext(AuthContext);
  const { goBack, setBackHandler, patient } = useContext(PatientHomeContext);
  const [alertsList, updateAlertsList] = useState(null);
  const [period, setPeriod] = useState('3m');
  const alertsListUpdateSubscription = useRef();
  const { subscribe } = useContext(SocketContext);
  const [selectedCategory, updateSelectedCategory] = useState(null);
  const [queryAlerts] = useLazyQuery(FETCH_PATIENT_ALERTS, {
    variables: { patient: patient?._id, period },
    onCompleted: (data) => {
      updateAlertsList(data.patientAlerts);
    },
    onError: () => {
      updateAlertsList([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchAlerts = useCallback(() => {
    if (patient) {
      updateAlertsList(null);
      queryAlerts();
    }
  }, [patient, queryAlerts]);

  useEffect(() => {
    if (!!period) {
      fetchAlerts();
      alertsListUpdateSubscription.current?.unsubscribe();
      alertsListUpdateSubscription.current = subscribe(
        EVENTS.ALERTS_UPDATE,
        () => {
          fetchAlerts();
        }
      );
    }
    return () => {
      alertsListUpdateSubscription.current?.unsubscribe();
    };
  }, [patient, period, fetchAlerts, subscribe]);

  const alertsCategories = useMemo(() => {
    const titles = ['vital', 'medication', 'activity', 'wellness', 'diet'];
    const categories = {};
    titles.forEach((title) => {
      categories[title] = {
        name: title,
        lastAlert: null,
        alerts: [],
        unseenCount: 0,
      };
    });
    alertsList?.forEach((alert) => {
      categories[alert.measure]?.alerts.push(alert);
      if (!categories[alert.measure].lastAlert) {
        categories[alert.measure].lastAlert = alert.triggerTime;
      }
      if (!alert.isSeen) {
        categories[alert.measure].unseenCount++;
      }
    });
    return Object.values(categories);
  }, [alertsList]);

  useEffect(() => {
    if (selectedCategory) {
      setBackHandler(() => {
        updateSelectedCategory(null);
        return false;
      });
    }
  }, [selectedCategory]);

  const [bookAppointment] = useMutation(BOOK_APPOINTMENT, {
    onCompleted: (appointment) => {
      goBack();
    },
  });

  const handleBook = async (time) => {
    loadingVar(true);
    await bookAppointment({
      variables: {
        appointment: {
          provider: me._id,
          patient: patient._id,
          time,
        },
      },
    });
    loadingVar(false);
  };

  useEffect(() => {
    setBackHandler('');
  }, []);

  const handleAfterRead = (category) => {
    for (let alert of alertsList) {
      if (alert.measure !== category) {
        continue;
      }
      alert.isSeen = true;
    }
    updateAlertsList([...alertsList]);
  };

  return (
    <Container
      modifiers={['fluid', 'fullHeight', 'flexBox', 'backgroundWhite']}
    >
      {me && patient ? (
        selectedCategory ? (
          <CategoryAlerts
            categoryAlerts={selectedCategory}
            handleBook={handleBook}
            me={me}
            patient={patient}
            handleAfterRead={handleAfterRead}
            goBack={goBack}
          />
        ) : (
          <AlertsCategories
            handleBook={handleBook}
            handleCategoryClick={(category) =>
              category.alerts?.length > 0 && updateSelectedCategory(category)
            }
            categories={alertsCategories}
            me={me}
            patient={patient}
            period={period}
            setPeriod={setPeriod}
            goBack={goBack}
          />
        )
      ) : (
        <></>
      )}
    </Container>
  );
};
