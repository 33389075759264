import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'style';

const modifierConfig = {
  noCenter: ({ theme }) => `
    justify-content: start;
    width: 100%;
    border-radius: ${px2rem(8)};
  `,
};

const styles = ({ theme }) => `
  width: ${px2rem(52)};
  height: ${px2rem(52)};
  padding: ${px2rem(5)};
  background-color: ${theme.colors.white};
  border-radius: ${px2rem(4)};
  box-shadow: ${theme.dimensions.widgetBoxShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${px2rem(8)};
  position: relative;
`;

export default buildStyledComponent('Widget_Wrapper', styled.div, styles, {
  modifierConfig,
});
