import styled from 'styled-components';
import { Container } from '@gaz/gaz-components.public';
import { buildStyledComponent } from 'style';

const styles = ({ theme }) => `
  background-color: ${theme.colors.lightBlueBrand};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default buildStyledComponent(
  'AuthLayout_Wrapper',
  styled(Container),
  styles
);
