import {
  createContext,
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_PATIENT_CAREPLANS, FETCH_NOTES } from 'graphql/queries';

import { EVENTS, SocketContext } from 'contexts/socket';

export const CoordinateContext = createContext();

const CoordinateProvider = ({ children }) => {
  const [careplans, setCareplans] = useState(null);
  const [notes, setNotes] = useState(null);
  const [careplanPeriod, updateCareplanPeriod] = useState('3m');
  const [notePeriod, updateNotePeriod] = useState('3m');
  const { subscribe } = useContext(SocketContext);
  const notesListUpdateSubscription = useRef();
  const careplanListUpdateSubscription = useRef();

  const [queryCareplans] = useLazyQuery(FETCH_PATIENT_CAREPLANS, {
    variables: {
      period: careplanPeriod,
      status: 'shared',
    },
    onCompleted: (data) => {
      setCareplans(data.patientCareplans);
    },
    onError: () => {
      setCareplans([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchCareplans = useCallback(() => {
    setCareplans(null);
    queryCareplans();
  }, []);

  useEffect(() => {
    if (!!careplanPeriod) {
      fetchCareplans();
      careplanListUpdateSubscription.current?.unsubscribe();
      careplanListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_CAREPLANS,
        () => {
          fetchCareplans();
        }
      );
    }
    return () => {
      careplanListUpdateSubscription.current?.unsubscribe();
    };
  }, [careplanPeriod, fetchCareplans]);

  const [queryNotes] = useLazyQuery(FETCH_NOTES, {
    variables: { period: notePeriod, status: 'shared' },
    onCompleted: (data) => {
      setNotes(data.notes);
    },
    onError: () => {
      setNotes([]);
    },
    fetchPolicy: 'no-cache',
  });

  const fetchNotes = useCallback(() => {
    setNotes(null);
    queryNotes();
  }, []);

  useEffect(() => {
    if (!!notePeriod) {
      fetchNotes();
      notesListUpdateSubscription.current?.unsubscribe();
      notesListUpdateSubscription.current = subscribe(
        EVENTS.PATIENT_NOTES,
        () => {
          fetchNotes();
        }
      );
    }
    return () => {
      notesListUpdateSubscription.current?.unsubscribe();
    };
  }, [notePeriod, fetchNotes]);

  return (
    <CoordinateContext.Provider
      value={{
        careplans,
        fetchCareplans,
        careplanPeriod,
        updateCareplanPeriod,
        notes,
        fetchNotes,
        notePeriod,
        updateNotePeriod,
      }}
    >
      {children}
    </CoordinateContext.Provider>
  );
};

export default CoordinateProvider;
