import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';

import { FETCH_CAREPLAN } from 'graphql/queries';
import { READ_PATIENT_CAREPLAN } from 'graphql/mutations';
import { loadingVar } from 'graphql/cache';
import CareplanDetail from 'pages/Careplans/CareplanDetail';

export default () => {
  const history = useHistory();
  const { id } = useParams();
  const [readCareplan] = useMutation(READ_PATIENT_CAREPLAN, {
    variables: { careplan: id },
  });
  const { data: { careplan } = {}, loading } = useQuery(FETCH_CAREPLAN, {
    variables: { id },
  });

  useEffect(() => {
    if (careplan && !careplan.isDraft && !careplan.isSeen) {
      readCareplan();
    }
  }, [careplan]);

  loadingVar(loading);

  if (loading) {
    return null;
  }

  return careplan ? (
    <CareplanDetail
      careplan={careplan}
      goBack={() => history.replace('/coordinate/care-plans')}
      noFooter
    />
  ) : (
    <div>No Careplan</div>
  );
};
