import { gql } from '@apollo/client';

import fragments from './fragments';

export const LOGIN = gql`
  mutation Login(
    $email: String!
    $password: String
    $pin: String
    $role: String
    $timezoneOffset: Int
    $timezone: String
    $isProvider: Boolean
  ) {
    login(
      email: $email
      password: $password
      pin: $pin
      role: $role
      timezoneOffset: $timezoneOffset
      timezone: $timezone
      isProvider: $isProvider
    ) {
      ...AuthFragment
    }
  }
  ${fragments.authFragment}
`;

export const REGISTER_PROVIDER = gql`
  mutation RegisterProvider(
    $provider: ProviderInput!
    $timezoneOffset: Int
    $timezone: String
  ) {
    registerProvider(
      provider: $provider
      timezoneOffset: $timezoneOffset
      timezone: $timezone
    ) {
      ...AuthFragment
    }
  }
  ${fragments.authFragment}
`;

export const UPDATE_ME = gql`
  mutation UpdateMe($provider: ProviderInput!) {
    updateMe(provider: $provider) {
      ...AuthFragment
    }
  }
  ${fragments.authFragment}
`;

export const UPDATE_SECURITY = gql`
  mutation UpdateSecurity($security: UserSecurityInput!) {
    updateSecurity(security: $security)
  }
`;

export const REGISTER_PRACTICE = gql`
  mutation RegisterPractice($practice: PracticeInput!) {
    registerPractice(practice: $practice) {
      ...AuthFragment
    }
  }
  ${fragments.authFragment}
`;

export const REQUEST_CODE = gql`
  mutation RequestCode($fullName: String!, $email: String!, $phone: String!) {
    requestCode(fullName: $fullName, email: $email, phone: $phone)
  }
`;

export const VERIFY_CODE = gql`
  mutation VerifyCode($email: String!, $code: String!) {
    verifyCode(email: $email, code: $code) {
      ...AuthFragment
    }
  }
  ${fragments.authFragment}
`;

export const SIGN_S3 = gql`
  mutation SignS3($folder: String!, $fileType: String!) {
    signS3(folder: $folder, fileType: $fileType) {
      signedRequest
      url
    }
  }
`;

export const CREATE_PATIENT = gql`
  mutation CreatePatient($patient: PatientInput!) {
    createPatient(patient: $patient) {
      user {
        ...UserFragment
      }
      practice {
        ...PracticeFragment
      }
    }
  }
  ${fragments.userFragment}
  ${fragments.practiceFragment}
`;

export const SAVE_NOTE_DRAFT = gql`
  mutation SaveNoteDraft($note: NoteInput!) {
    saveNoteDraft(note: $note) {
      ...NoteFragment
    }
  }
  ${fragments.noteFragment}
`;

export const SIGN_NOTE = gql`
  mutation SignNote($note: NoteInput!) {
    signNote(note: $note) {
      ...NoteFragment
    }
  }
  ${fragments.noteFragment}
`;

export const SAVE_CAREPLAN_DRAFT = gql`
  mutation SaveCareplanDraft($careplan: CareplanInput!) {
    saveCareplanDraft(careplan: $careplan) {
      ...CareplanFragment
    }
  }
  ${fragments.careplanFragment}
`;

export const SIGN_CAREPLAN = gql`
  mutation SignCareplan($careplan: CareplanInput!) {
    signCareplan(careplan: $careplan) {
      ...CareplanFragment
    }
  }
  ${fragments.careplanFragment}
`;

export const FETCH_OR_CREATE_CHAT = gql`
  mutation FetchOrCreateChat($chat: ChatInput!) {
    fetchOrCreateChat(chat: $chat) {
      ...ChatFragment
    }
  }
  ${fragments.chatFragment}
`;

export const CREATE_RECORD = gql`
  mutation CreateRecord($record: RecordInput!) {
    createRecord(record: $record)
  }
`;

export const UPDATE_CALL_STATUS = gql`
  mutation UpdateCallStatus($call: String!, $status: String!, $time: Date!) {
    updateCallStatus(call: $call, status: $status, time: $time)
  }
`;

export const BOOK_APPOINTMENT = gql`
  mutation BookAppointment($appointment: AppointmentInput!) {
    bookAppointment(appointment: $appointment) {
      ...AppointmentFragment
    }
  }
  ${fragments.appointmentFragment}
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment($appointment: ID!) {
    cancelAppointment(appointment: $appointment)
  }
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation RescheduleAppointment(
    $appointment: ID!
    $newAppointment: AppointmentInput!
  ) {
    rescheduleAppointment(
      appointment: $appointment
      newAppointment: $newAppointment
    ) {
      ...AppointmentFragment
    }
  }
  ${fragments.appointmentFragment}
`;

export const INVITE_EXISTING_USER = gql`
  mutation InviteExistingUser($inviteeId: ID!) {
    inviteExistingUser(inviteeId: $inviteeId)
  }
`;

export const INVITE_EXISTING_USERS_TO_GROUP = gql`
  mutation InviteExistingUsersToGroup(
    $inviteeIds: [ID]!
    $groupId: ID!
    $isPractice: Boolean!
  ) {
    inviteExistingUsersToGroup(
      inviteeIds: $inviteeIds
      groupId: $groupId
      isPractice: $isPractice
    )
  }
`;

export const REQUEST_TO_JOIN_GROUP = gql`
  mutation RequestToJoinGroup($groupId: ID!, $isPractice: Boolean!) {
    requestToJoinGroup(groupId: $groupId, isPractice: $isPractice)
  }
`;

export const INVITE_NEW_USER = gql`
  mutation InviteNewUser(
    $fullName: String!
    $email: String!
    $phone: String!
    $role: String!
  ) {
    inviteNewUser(
      fullName: $fullName
      email: $email
      phone: $phone
      role: $role
    ) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`;

export const INVITE_NEW_USER_TO_GROUP = gql`
  mutation InviteNewUser(
    $fullName: String!
    $email: String!
    $phone: String!
    $role: String!
    $groupId: ID!
    $isPractice: Boolean!
  ) {
    inviteNewUser(
      fullName: $fullName
      email: $email
      phone: $phone
      role: $role
      groupId: $groupId
      isPractice: $isPractice
    )
  }
`;

export const ACCEPT_OR_DECLINE_INVITE = gql`
  mutation AcceptOrDeclineInvite($id: ID!, $accepting: Boolean!) {
    acceptOrDeclineInvite(id: $id, accepting: $accepting)
  }
`;

export const CANCEL_INVITE = gql`
  mutation CancelInvite($id: ID!) {
    cancelInvite(id: $id)
  }
`;

export const ORDER_MEDS = gql`
  mutation OrderMedications($medications: [MedicationInput!], $patient: ID!) {
    orderMedications(patient: $patient, medications: $medications) {
      ...MedicationOrderFragment
    }
  }
  ${fragments.medicationOrderFragment}
`;

export const PROCESS_MED_ORDER = gql`
  mutation ProcessMedicationOrder(
    $order: ID!
    $status: String!
    $tracking: String
  ) {
    processOrder(order: $order, status: $status, tracking: $tracking)
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($group: GroupInput!) {
    createGroup(group: $group) {
      ...GroupFragment
    }
  }
  ${fragments.groupFragment}
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($id: ID!, $group: GroupInput!) {
    updateGroup(id: $id, group: $group) {
      ...GroupFragment
    }
  }
  ${fragments.groupFragment}
`;

export const UPDATE_PRACTICE = gql`
  mutation UpdatePractice($id: ID!, $group: PracticeInput!) {
    updatePractice(id: $id, group: $group) {
      ...PracticeFragment
    }
  }
  ${fragments.practiceFragment}
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($id: ID!, $patient: PatientInput!) {
    updatePatient(id: $id, patient: $patient) {
      ...UserProfileFragment
    }
  }
  ${fragments.userProfileFragment}
`;

export const UPDATE_OPERATION_SCHEDULE = gql`
  mutation UpdateOperationSchedule($schedule: [ScheduleInput!]) {
    updateOperationSchedule(schedule: $schedule)
  }
`;

export const ADD_CAREPLAN_RESPONSE = gql`
  mutation AddCareplanResponse(
    $patient: ID!
    $date: Date
    $response: CareplanResponseInput!
  ) {
    addResponse(patient: $patient, date: $date, response: $response) {
      ...CareplanResponseFragment
    }
  }
  ${fragments.careplanResponseFragment}
`;

export const SHARE_NOTE = gql`
  mutation ShareNote($provider: ID!, $note: ID!) {
    shareNote(provider: $provider, note: $note)
  }
`;

export const SHARE_CAREPLAN = gql`
  mutation ShareCareplan($provider: ID!, $careplan: ID!) {
    shareCareplan(provider: $provider, careplan: $careplan)
  }
`;

export const INIT_VERIFY_NUMBER = gql`
  mutation InitVerifyNumber($number: String!) {
    initVerifyNumber(number: $number)
  }
`;

export const UPDATE_CALL_MASKING = gql`
  mutation UpdateCallMasking($masking: String!) {
    updateCallMasking(masking: $masking) {
      ...UserProfileFragment
    }
  }
  ${fragments.userProfileFragment}
`;

export const SAVE_RECENT_PATIENTS = gql`
  mutation SaveRecentPatients($recentPatients: [String!]) {
    saveRecentPatients(recentPatients: $recentPatients) {
      ...UserProfileFragment
    }
  }
  ${fragments.userProfileFragment}
`;

export const READ_PATIENT_ALERTS = gql`
  mutation readAlerts($patient: ID!, $measure: String!, $alert: ID!) {
    readAlerts(patient: $patient, measure: $measure, alert: $alert)
  }
`;

export const READ_PATIENT_NOTE = gql`
  mutation readNote($note: ID!) {
    readNote(note: $note)
  }
`;

export const READ_PATIENT_CAREPLAN = gql`
  mutation readCareplan($careplan: ID!) {
    readCareplan(careplan: $careplan)
  }
`;
