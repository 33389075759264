import React from 'react';
import { noop } from 'lodash';

import { HR } from '@gaz/gaz-components.public';
import { SlidingPaneContent } from 'common';
import assets from 'assets';

import { SHARE_OPTIONS } from './constants';

export default function ShareOptions({
  hasPatient,
  hasDirectPatient,
  onClickAction = noop,
}) {
  return (
    <div>
      {hasPatient ? /*(
        <SlidingPaneContent
          actionTitle="Add To Note"
          actionDescription="Add selected message to patient note"
          actionIcon={assets.icons.icAddToNote}
          onClickAction={onClickAction(SHARE_OPTIONS.ADD_NOTE)}
        />
      )*/ null : (
        <SlidingPaneContent
          actionTitle="Reference Patient"
          actionDescription="Track care coordination time with other providers"
          actionIcon={assets.icons.icRefPatient}
          onClickAction={onClickAction(SHARE_OPTIONS.REFER_PATIENT)}
        />
      )}
      <HR modifiers={['colored']} />
      <SlidingPaneContent
        actionTitle="Add Participants"
        actionDescription="Add more participants to this chat"
        actionIcon={assets.icons.icGroupPlus}
        onClickAction={onClickAction(SHARE_OPTIONS.ADD_PARTICIPANT)}
      />
      <HR modifiers={['colored']} />
      <SlidingPaneContent
        actionTitle="Add Attachments"
        actionDescription="View conversation attachments"
        actionIcon={assets.icons.icAttachmentRed}
        onClickAction={onClickAction(SHARE_OPTIONS.ADD_ATTACHMENT)}
      />
      <HR modifiers={['colored']} />
      {/* {hasDirectPatient && (
        <SlidingPaneContent
          actionTitle="Book Appointment"
          actionIcon={assets.icons.icScheduleGold}
          onClickAction={onClickAction(SHARE_OPTIONS.BOOK_APPOINTMENT)}
          noSeparator
        />
      )} */}
    </div>
  );
}
